import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./MembershipPrice.css";
import { Link, useNavigate } from "react-router-dom";
import { getCall } from "../../../utils/AxiosInterseptor";
import { FETCH_MEMBERSHIP_PRICE } from "../../../utils/axiosUtil";
import {
    decryptDataFunction,
    encryptDataFunction,
    formatNumberWithCommas,
} from "../../../utils/utils";
import call from "../../../assets/call-icon.png";
import chat from "../../../assets/chat-icon.png";
import whatsapp from "../../../assets/whatsapp-icon.png";

const PriceCard = ({ buyFlag, data, onClick, selected }) => {
    const { levelName, Price } = data;
    let src,
        alt,
        errorSrc = "";
    switch (levelName) {
        case "Copper":
            src = process.env.REACT_APP_PRICE_CARD_COPPER_IMAGE_SRC;
            errorSrc = process.env.REACT_APP_PRICE_CARD_COPPER_IMAGE_SRC;
            alt = levelName;
            break;
        case "Silver":
            src = process.env.REACT_APP_PRICE_CARD_SILVER_IMAGE_SRC;
            errorSrc = process.env.REACT_APP_PRICE_CARD_SILVER_IMAGE_SRC;
            alt = levelName;
            break;
        case "Gold":
            src = process.env.REACT_APP_PRICE_CARD_GOLD_IMAGE_SRC;
            errorSrc = process.env.REACT_APP_PRICE_CARD_GOLD_IMAGE_SRC;
            alt = levelName;
            break;
        case "Platinum":
            src = process.env.REACT_APP_PRICE_CARD_PLATINUM_IMAGE_SRC;
            errorSrc = process.env.REACT_APP_PRICE_CARD_PLATINUM_IMAGE_SRC;
            alt = levelName;
            break;
        default:
            break;
    }
    return (
        <Col lg={3} xs={6}>
            <div
                className={`innerSection ${buyFlag ? "cp" : ""} ${
                    selected ? "selected" : ""
                }`}
                onClick={onClick}
            >
                <img
                    className="img-fluid"
                    src={src}
                    alt={alt}
                    onError={(e) => {
                        e.currentTarget.src = errorSrc;
                    }}
                />
                <h4>{levelName}</h4>
                <p>&#8377; {formatNumberWithCommas(Price)}</p>
                <span>+ Taxes</span>
            </div>
        </Col>
    );
};

const MembershipPrice = ({ property, buyFlag }) => {
    const navigate = useNavigate();
    const [membershipPriceData, setMembershipPriceData] = useState([]);
    const [selectedMembership, setSelectedMembership] = useState({
        id: "TLC_RAD_PLA",
        name: "Platinum",
    });
    const membershipSelectionHandler = (plan) => {
        if (!buyFlag) {
            return;
        }
        sessionStorage.setItem("plan", encryptDataFunction(plan));
        setSelectedMembership({
            id: plan.customersetUniqueIdentifier,
            name: plan.levelName,
        });
    };
    useEffect(() => {
        const plan = decryptDataFunction(sessionStorage.getItem("plan"));
        setSelectedMembership({
            id: plan.customersetUniqueIdentifier ?? "TLC_RAD_PLA",
            name: plan.levelName ?? "Platinum",
        });
    }, []);
    useEffect(() => {
        let flag = false;
        const fetchMemberPriceData = async () => {
            flag = true;
            const savedMemberPriceData = decryptDataFunction(
                sessionStorage.getItem("pln_mstr_data")
            );
            if (savedMemberPriceData) {
                let priceData = savedMemberPriceData[property];
                if (property === "jalandhar") {
                    priceData = [
                        {
                            levelName: "Silver",
                            // customersetUniqueIdentifier: "TLC_RAD_JAL_SILVER",
                            // customerSetID: "a0J1y000007ZcxnEAC",
                            currencycode: "INR",
                            Price: 7500,
                            tax_1: 18,
                            finalPrice: "8850",
                            // isPromocodeDriven: false,
                        },
                    ];
                }
                setMembershipPriceData(priceData);
                return;
            }
            const response = await getCall(FETCH_MEMBERSHIP_PRICE());
            if (response.msg !== "Success") {
                return;
            }
            const citiesArray = response.data?.countries[0]?.cities;
            const delhi = citiesArray.find((city) => city.cityid === "274");
            const jalandhar = citiesArray.find(
                (city) => city.cityid === "1144"
            );
            const orderedMembershipPriceData =
                delhi?.properties[0]?.levels?.sort((a, b) => {
                    const order = {
                        Copper: 1,
                        Silver: 2,
                        Gold: 3,
                        Platinum: 4,
                    };
                    return order[a.levelName] - order[b.levelName];
                });
            let masterPriceData = {
                delhi: orderedMembershipPriceData ?? [],
                jalandhar: jalandhar?.properties[0]?.levels ?? [],
            };
            const encryptedMembershipPriceData =
                encryptDataFunction(masterPriceData);
            sessionStorage.setItem(
                "pln_mstr_data",
                encryptedMembershipPriceData
            );
            if (property === "jalandhar") {
                setMembershipPriceData([
                    {
                        levelName: "Silver",
                        // customersetUniqueIdentifier: "TLC_RAD_JAL_SILVER",
                        // customerSetID: "a0J1y000007ZcxnEAC",
                        currencycode: "INR",
                        Price: 7500,
                        tax_1: 18,
                        finalPrice: "8850",
                        // isPromocodeDriven: false,
                    },
                ]);
            } else {
                setMembershipPriceData(masterPriceData[property]);
            }
        };
        if (membershipPriceData.length === 0 && !flag) {
            fetchMemberPriceData();
        }
    }, [membershipPriceData.length, property]);
    return (
        <>
            <Container className="homepage-container">
                <section className="membership-price">
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <h3>
                                {membershipPriceData?.length !== 1
                                    ? "Membership Prices"
                                    : "Membership Price"}
                            </h3>
                        </Col>
                        {membershipPriceData.map((data, i) => (
                            <PriceCard
                                buyFlag={buyFlag}
                                selected={
                                    buyFlag
                                        ? data.customersetUniqueIdentifier ===
                                          selectedMembership.id
                                        : false
                                }
                                key={i}
                                data={data}
                                onClick={membershipSelectionHandler.bind(
                                    null,
                                    data
                                )}
                            />
                        ))}
                        <Col md={12}>
                            {buyFlag ? (
                                <button
                                    className="membership-price-button"
                                    onClick={() => {
                                        const savedPlan =
                                            sessionStorage.getItem("plan");
                                        if (!savedPlan) {
                                            sessionStorage.setItem(
                                                "plan",
                                                encryptDataFunction(
                                                    membershipPriceData[3]
                                                )
                                            );
                                        }
                                        navigate("/membership-details");
                                    }}
                                >
                                    Buy Now
                                </button>
                            ) : (
                                <div className="contact-channel">
                                    <Link to="tel:+91 11 46644600">
                                        <img
                                            className="img-fluid contact-icon"
                                            src={call}
                                            alt="Prestige Club Call Now icon"
                                        />
                                    </Link>
                                    <Link to="mailto:contactus@prestigeclub.in">
                                        <img
                                            className="img-fluid contact-icon"
                                            src={chat}
                                            alt="Prestige Club Chat Now icon"
                                        />
                                    </Link>
                                    <Link
                                        to="https://wa.me/+919821298085"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            className="img-fluid contact-icon"
                                            src={whatsapp}
                                            alt="Prestige Club WhatsApp Now icon"
                                        />
                                    </Link>
                                </div>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </>
    );
};

export default React.memo(MembershipPrice);
