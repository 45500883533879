import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import Footer from "../components/footer/Footer";
import bannerImage from "../assets/terms-banner-web.webp";
import bannerImageMobile from "../assets/terms-banner-mobile.webp";
import TermSection from "../components/TermSection/TermSection";
import { paymentGateway } from "../utils/termsAndConditionData";
import { Helmet } from "react-helmet";

const PaymentGatewayPage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Please refer to the payment terms and conditions associated with the Prestige Club membership here."
                />
                <title>Prestige Club | Payment Terms and Conditions</title>
            </Helmet>
            <Header />
            <HeroSection src={bannerImage} mobileSrc={bannerImageMobile} />
            <TermSection data={paymentGateway} />
            <Footer />
        </>
    );
};

export default PaymentGatewayPage;
