export const delhiAnnualBenefitData = {
    columnData: ["Copper", "Silver", "Gold", "Platinum"],
    rowData: [
        {
            benefit: "50% off when 2 guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "33% off when 3 guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "25% off when 4 guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "20% off when 5 or more guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "20% off when 1 guest dines",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "30% off when 3 to 10 guests dine or when Member dines alone",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "20% off on beverages. Not valid on bottle rates",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "50% off on food and soft beverages for up to 10 guests on Monday and Tuesday at NYC or Savannah",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "30% off on food and soft beverages for up to 10 guests on Monday and Tuesday at TGKF",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "50% off on food and 20% off on beverages over lunch for up to 10 guests on Thursday and Sunday at Neung Roi",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "50% off on food and 20% off on beverages for up to 20 ladies every Saturday and Sunday at Neung Roi",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Happy Hours 1 + 1 on select brands only from Monday to Thursday between 5 pm and 9 pm.",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Happy Hours 1 + 1 on select brands, 5 pm onwards.",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },

        {
            benefit: "Pastry Shop",
            copper: "20% ",
            silver: "20% ",
            gold: "25% ",
            platinum: "30% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Spa & Salon",
            copper: "30% ",
            silver: "30% ",
            gold: "40% ",
            platinum: "50% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Laundry",
            copper: "30% ",
            silver: "30% ",
            gold: "40% ",
            platinum: "50% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Member's Day (Spa & Salon) - Every Wednesday",
            copper: "50% ",
            silver: "50% ",
            gold: "50% ",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Business Centre",
            copper: "20% ",
            silver: "20% ",
            gold: "25% ",
            platinum: "30% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Health Club Membership",
            copper: "15% ",
            silver: "15% ",
            gold: "20% ",
            platinum: "30% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
    ],
};

export const delhiOneTimeBenefitData = {
    columnData: ["Copper", "Silver", "Gold", "Platinum"],
    rowData: [
        {
            benefit: "Buffet Meal for 2 guests at NYC",
            copper: "1",
            silver: "1",
            gold: "1",
            platinum: "1",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "House Wine / Cake (max 1 Kg)",
            copper: "2",
            silver: "2",
            gold: "2",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Dining at NYC or Neung Roi",
            copper: "2",
            silver: "2",
            gold: "3",
            platinum: "4",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Dining at TGKF",
            copper: "1",
            silver: "1",
            gold: "2",
            platinum: "3",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Sunday Brunch at the NYC",
            copper: "1",
            silver: "1",
            gold: "1",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "30% off on Dining at TGKF",
            copper: "-",
            silver: "-",
            gold: "1",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Night stay in Superior Room inclusive of Buffet Breakfast for 2 guests",
            copper: "-",
            silver: "1 ",
            gold: "1 ",
            platinum: "- ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Stay in Business Class Room inclusive of Buffet Breakfast for 2 guests",
            copper: "-",
            silver: "-",
            gold: "1",
            platinum: "1",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Stay in Executive Suite inclusive of Buffet Breakfast for 2 guests",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "1",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on body massage for 1 hour",
            copper: "-",
            silver: "-",
            gold: "1",
            platinum: "3",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Body massage for 1 hour",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Business Centre usage",
            copper: "1",
            silver: "1",
            gold: "1",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
    ],
};

export const jalandharAnnualBenefitData = {
    columnData: ["Silver"],
    rowData: [
        {
            benefit: "25% off on food and beverage bill for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "20% off on alcoholic beverages for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "25% off on non-alcoholic beverages for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit: "20% off on the F&B bill at all participating hotels",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "Member’s Day every Tuesday with 50% off on the food bill and 20% on beverages for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "Happy Hours Monday to Friday with 1+1 on IMFL from 6 pm to 8 pm",
            silver: "",
            silverSub: "",
        },
        {
            benefit: "Pastry Shop",
            silver: "20%",
            silverSub: "",
        },
        {
            benefit: "Pastry Shop after 7:30 pm from Monday to Friday",
            silver: "30%",
            silverSub: "",
        },
        {
            benefit: "Stay",
            silver: "20%",
            silverSub: "",
        },
        {
            benefit: "Spa and Salon",
            silver: "20%",
            silverSub: "",
        },
        {
            benefit: "Health Club membership",
            silver: "15%",
            silverSub: "",
        },
    ],
};

export const jalandharOneTimeBenefitData = {
    columnData: ["Silver"],
    rowData: [
        {
            benefit: "Buffet Lunch for 2 guests",
            silver: "One",
            silverSub: "",
        },
        {
            benefit: "Cake (max 1 kg)",
            silver: "One",
            silverSub: "",
        },
        {
            benefit: "50% off on Cake (min 2 kg and max 5 kg)",
            silver: "One",
            silverSub: "",
        },
        {
            benefit:
                "One dessert for each main course ordered for minimum 4 and maximum 10 guests",
            silver: "Two",
            silverSub: "",
        },
        {
            benefit:
                "25% off on the food bill over lunch at The Great Kabab Factory for a minimum of 30 guests",
            silver: "Two",
            silverSub: "",
        },
        {
            benefit: "Night stay including Buffet Breakfast for 2 guests",
            silver: "One",
            silverSub: "",
        },
        {
            benefit:
                "30% off on the published room tariff over weekends from June to September",
            silver: "One",
            silverSub: "",
        },
        {
            benefit: "Upgrade to a Club Room",
            silver: "One",
            silverSub: "",
        },
        {
            benefit:
                "Swimming pool access for 2 guests for a maximum of 4 hours",
            silver: "Two",
            silverSub: "",
        },
        {
            benefit: "50% off at the Spa or Salon",
            silver: "One",
            silverSub: "",
        },
    ],
};
