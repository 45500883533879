import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import Footer from "../components/footer/Footer";
import bannerImage from "../assets/app-terms-banner-web.webp";
import bannerImageMobile from "../assets/app-terms-banner-mobile.webp";
import TermSection from "../components/TermSection/TermSection";
import { appTermsAndConditions } from "../utils/termsAndConditionData";
import { Helmet } from "react-helmet";

const AppTermsAndConditionsPage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="The terms and conditions governing the Prestige Club App may be accessed on this page."
                />
                <title>Prestige Club | App Terms and Conditions</title>
            </Helmet>
            <Header />
            <HeroSection src={bannerImage} mobileSrc={bannerImageMobile} />
            <TermSection data={appTermsAndConditions} />
            <Footer />
        </>
    );
};

export default AppTermsAndConditionsPage;
