import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { EMAIL_REGEX, NUMBER_REGEX } from "../../../utils/regex";
import "react-international-phone/style.css";
import { CountrySelectorDropdown } from "react-international-phone";
import ReCAPTCHA from "react-google-recaptcha";
import downArrow from "../../../assets/dropdown.png";
import countryCodes from "../../../utils/countryCodes.json";
import { getCall } from "../../../utils/AxiosInterseptor";
import { VALIDATE_SIGNUP_PROMOCODE } from "../../../utils/axiosUtil";
import { Link } from "react-router-dom";

const KnowMoreForm = ({ knowMoreInnerBlockRef }) => {
    const [countrySelectShow, setCountrySelectShow] = useState(false);
    const [countryData, setCountryData] = useState({
        isd: "91",
        iso2: "in",
        minLength: 10,
        maxLength: 10,
    });
    const [captcha, setCaptcha] = useState("");
    const [formData, setFormData] = useState({
        salutation: "",
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        "00N1y000004n5m3": "", //PROMOCODE
        tc: false,
    });
    const [formDataValid, setFormDataValid] = useState({
        salutation: true,
        first_name: true,
        last_name: true,
        mobile: true,
        email: true,
        "00N1y000004n5m3": true, //PROMOCODE
        tc: true,
        captcha: true,
    });
    const formDataChangeHandler = (e) => {
        let { name, value } = e.target;
        if (name === "tc") {
            value = e.target.checked;
        }
        if (name === "first_name" || name === "last_name") {
            if (value[0]) {
                value = value[0].toUpperCase() + value.slice(1);
            }
        }
        if (name === "mobile") {
            const isNum = NUMBER_REGEX.test(value);
            if (!isNum || value.length > countryData.maxLength) return;
        }
        if (name === "email") {
            value = value.trim();
        }
        if (name === "00N1y000004n5m3") {
            value = value.trim().toUpperCase();
        }
        const data = Object.keys(formDataValid);
        if (data.includes(name)) {
            setFormDataValid((prev) => ({ ...prev, [name]: true }));
        }
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const captchaChangeHandler = (captcha) => {
        setFormDataValid((prev) => ({ ...prev, captcha: true }));
        setCaptcha(captcha ?? "");
    };
    const showError = (data) => {
        setFormDataValid(data);
    };
    const validateInput = () => {
        const salutationValid = formData.salutation !== "";
        const firstNameValid = formData.first_name !== "";
        const lastNameValid = formData.last_name !== "";
        const mobileValid =
            formData.mobile.length >= countryData.minLength &&
            formData.mobile.length <= countryData.maxLength;
        const emailValid = EMAIL_REGEX.test(formData.email);
        const captchaValid = captcha.length !== 0;
        if (
            !salutationValid ||
            !firstNameValid ||
            !lastNameValid ||
            !mobileValid ||
            !emailValid ||
            !captchaValid ||
            !formData.tc
        ) {
            const data = {
                salutation: salutationValid,
                first_name: firstNameValid,
                last_name: lastNameValid,
                mobile: mobileValid,
                email: emailValid,
                "00N1y000004n5m3": true,
                tc: formData.tc ? true : false,
                captcha: captchaValid,
            };
            showError(data);
            return false;
        }
        return true;
    };
    const validatePromoCode = async () => {
        if (formData["00N1y000004n5m3"].length === 0) {
            return true;
        }
        const response = await getCall(
            VALIDATE_SIGNUP_PROMOCODE(formData["00N1y000004n5m3"])
        );
        if (response.msg !== "Success") {
            setFormDataValid((prev) => ({ ...prev, "00N1y000004n5m3": false }));
            return false;
        }
        return true;
    };
    const formSubmitHandler = async (e) => {
        e.preventDefault();
        const isFormDataValid = validateInput();
        if (!isFormDataValid) return;
        const isPromoCodeValid = await validatePromoCode();
        if (!isPromoCodeValid) return;
        e.target.submit();
    };
    const countryCodeSelectionHandler = (e) => {
        setCountrySelectShow(false);
        const country = countryCodes.find((count) => count.iso2 === e.iso2);
        setCountryData({
            isd: e.dialCode,
            iso2: e.iso2,
            minLength: country?.Min ?? 10,
            maxLength: country?.Max ?? 10,
        });
        setFormData((prev) => ({ ...prev, mobile: "" }));
    };
    useEffect(() => {
        //FOR LOADING CAPTCHA
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    useEffect(() => {
        // Attach event listener to detect clicks outside the country selector dropdown
        const handleClickOutside = (event) => {
            if (!event.target.classList.contains("country-select")) {
                setCountrySelectShow(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        // Cleanup function to remove event listener when component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <>
            <Container className="mb-5 know-more-form-container">
                <div className="knowMoreFormBlock">
                    <div
                        className="knowMoreInnerBlock"
                        ref={knowMoreInnerBlockRef}
                    >
                        <Form
                            id="knowMoreForm"
                            onSubmit={formSubmitHandler}
                            method="POST"
                            action={
                                process.env.REACT_APP_KNOW_MORE_FORM_SUBMIT_URL
                            }
                        >
                            {/* SFDC HIDDEN FIELDS START */}
                            <input
                                type="hidden"
                                name="retURL"
                                value={process.env.REACT_APP_FORM_RETURN_URL}
                            />
                            <input
                                type="hidden"
                                name="oid"
                                value={process.env.REACT_APP_FORM_OID}
                            />
                            <input
                                name={process.env.REACT_APP_FORM_PROGRAM_KEY}
                                type="hidden"
                                value={process.env.REACT_APP_FORM_PROGRAM_VALUE}
                            />
                            <input name="country" type="hidden" value="India" />
                            <input
                                name="lead_source"
                                type="hidden"
                                value="website"
                            />
                            <input
                                name="Website_Page_Name__c"
                                type="hidden"
                                value="Become a Member"
                            />
                            {/* SFDC HIDDEN FIELDS END */}
                            <Row>
                                <Col md={12}>
                                    <h2>Know More</h2>
                                </Col>
                                <Col md={3} xs={3}>
                                    <Form.Select
                                        className={`${
                                            formData.salutation.length === 0
                                                ? "select-placeholder"
                                                : ""
                                        } ${
                                            formDataValid.salutation
                                                ? ""
                                                : "invalid-bottom"
                                        }`}
                                        aria-label="salutation"
                                        name="salutation"
                                        value={formData.salutation}
                                        onChange={formDataChangeHandler}
                                    >
                                        <option value="" disabled hidden>
                                            Title
                                        </option>
                                        <option value="Mr">Mr</option>
                                        <option value="Ms">Ms</option>
                                    </Form.Select>
                                </Col>
                                <Col md={4} xs={9}>
                                    <Form.Control
                                        className={`${
                                            formDataValid.first_name
                                                ? ""
                                                : "invalid-bottom"
                                        }`}
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={formDataChangeHandler}
                                        type="text"
                                        placeholder="First name"
                                    />
                                </Col>
                                <Col md={5} xs={12}>
                                    <Form.Control
                                        className={`${
                                            formDataValid.last_name
                                                ? ""
                                                : "invalid-bottom"
                                        }`}
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={formDataChangeHandler}
                                        type="text"
                                        placeholder="Last name"
                                    />
                                </Col>
                                <Col
                                    md={3}
                                    xs={3}
                                    className="position-relative knowmore-country-select"
                                >
                                    <button
                                        type="button"
                                        className="country-select country-select-button w-100 d-flex justify-content-between align-items-center"
                                        onClick={() => {
                                            setCountrySelectShow(
                                                !countrySelectShow
                                            );
                                        }}
                                    >
                                        <input
                                            type="hidden"
                                            name="00N0o00000NCGlK"
                                            value={`+${countryData.isd}`}
                                        />
                                        <span className="country-select">
                                            +{countryData.isd}
                                        </span>
                                        <img
                                            alt="V"
                                            className="country-select country-select-down-arrow"
                                            src={downArrow}
                                        />
                                    </button>
                                    <CountrySelectorDropdown
                                        show={countrySelectShow}
                                        selectedCountry={countryData.iso2}
                                        onSelect={countryCodeSelectionHandler}
                                    />
                                </Col>
                                <Col md={9} xs={9}>
                                    <Form.Control
                                        className={`${
                                            formDataValid.mobile
                                                ? ""
                                                : "invalid-bottom"
                                        }`}
                                        maxLength={countryData.maxLength}
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={formDataChangeHandler}
                                        type="tel"
                                        placeholder="Mobile Number"
                                    />
                                </Col>
                                <Col md={12}>
                                    <Form.Control
                                        className={`${
                                            formDataValid.email
                                                ? ""
                                                : "invalid-bottom"
                                        }`}
                                        name="email"
                                        value={formData.email}
                                        onChange={formDataChangeHandler}
                                        type="text"
                                        placeholder="Email"
                                    />
                                </Col>
                                <Col md={12}>
                                    <Form.Control
                                        className={`${
                                            formDataValid["00N1y000004n5m3"]
                                                ? ""
                                                : "invalid-bottom"
                                        }`}
                                        name="00N1y000004n5m3"
                                        value={formData["00N1y000004n5m3"]}
                                        onChange={formDataChangeHandler}
                                        type="text"
                                        placeholder="Promo Code"
                                    />
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Form.Check type="checkbox" id="tc">
                                            <Form.Check.Input
                                                className={`${
                                                    formDataValid.tc
                                                        ? "border-blue"
                                                        : "invalid"
                                                }`}
                                                type="checkbox"
                                                isValid
                                                name="tc"
                                                value={formData.tc}
                                                onChange={formDataChangeHandler}
                                            />
                                            <Form.Check.Label>
                                                By offering the content above, I
                                                give permission to Prestige Club
                                                to make contact with me to
                                                receive more information and
                                                agree to the&nbsp;
                                                <Link
                                                    to="https://tlcgroup.com/payment-terms"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Terms &amp; Conditions
                                                </Link>
                                                &nbsp;and the&nbsp;
                                                <Link
                                                    to="https://tlcgroup.com/privacy-policy"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Privacy Policy
                                                </Link>
                                                &nbsp;on the website.
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <ReCAPTCHA
                                        className={`${
                                            formDataValid.captcha
                                                ? ""
                                                : "invalid-captcha"
                                        }`}
                                        sitekey={
                                            process.env
                                                .REACT_APP_FORM_CAPTCH_SITE_KEY
                                        }
                                        onChange={captchaChangeHandler}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Button
                                        className="button submitbtn"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default React.memo(KnowMoreForm);
