import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./BuyMembershipSection.scss";
import StepOne from "./stepOne/StepOne";
import StepTwo from "./stepTwo/StepTwo";
import { getCall } from "../../utils/AxiosInterseptor";
import { GET_USER_DETAILS_FROM_LEAD } from "../../utils/axiosUtil";
import { decryptDataFunction, encryptDataFunction } from "../../utils/utils";
import CustomModal from "../custom-modal/CustomModal";
import { useNavigate } from "react-router-dom";
import linkExpiredIcon from "../../assets/link-expired.svg";
import linkInvalidIcon from "../../assets/link-invalid.svg";

const MembershipSteps = React.memo(({ step }) => {
    return (
        <div className="membership-steps-section">
            <div className="list-wrapper mobile-hide">
                <div className="red-line"></div>
                <div
                    className={`list-item-wrapper ${
                        step === "1" ? "active" : ""
                    }`}
                >
                    <div className="list-bullet"></div>
                    <div className="list-item">
                        <div className="list-title">Membership Details</div>
                    </div>
                </div>
                <div
                    className={`list-item-wrapper ${
                        step === "2" ? "active" : ""
                    }`}
                >
                    <div className="list-bullet"></div>
                    <div className="list-item">
                        <div className="list-title">Confirm Details</div>
                    </div>
                </div>
                <div
                    className={`list-item-wrapper ${
                        step === "3" ? "active" : ""
                    }`}
                >
                    <div className="list-bullet"></div>
                    <div className="list-item">
                        <div className="list-title">Payment</div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const MembershipStepsMobile = React.memo(({ step }) => {
    return (
        <div className="membership-steps-section-mobile">
            <div className={`step ${step === "1" ? "active" : ""}`}>
                <div className="step-circle"></div>
                <div className="step-title">Membership Details</div>
                <div className="step-bar-left"></div>
                <div className="step-bar-right"></div>
            </div>
            <div className={`step ${step === "2" ? "active" : ""}`}>
                <div className="step-circle"></div>
                <div className="step-title">Confirm Details</div>
                <div className="step-bar-left"></div>
                <div className="step-bar-right"></div>
            </div>
            <div className={`step ${step === "3" ? "active" : ""}`}>
                <div className="step-circle"></div>
                <div className="step-title">Payment</div>
                <div className="step-bar-left"></div>
                <div className="step-bar-right"></div>
            </div>
        </div>
    );
});

const BuyMembershipSection = () => {
    const navigate = useNavigate();
    const [errorModalState, setErrorModalState] = useState({
        modalId: "",
        show: false,
        content: "",
        icon: "",
        btnText: "",
    });
    const closeModal = () => {
        setErrorModalState({
            modalId: "",
            show: false,
            content: "",
            icon: "",
            btnText: "",
        });
        navigate("/");
    };
    const [step, setStep] = useState("1");
    const plan = decryptDataFunction(sessionStorage.getItem("plan"));
    useEffect(() => {
        const fetchUserDetailsFromUid = async (uId) => {
            const response = await getCall(GET_USER_DETAILS_FROM_LEAD(uId));
            if (response.msg !== "Success") {
                let modalId = "#something-went-wrong";
                let msg = "Something went wrong";
                let icon = linkInvalidIcon;
                if (response.errorCode === "TLC-1006") {
                    modalId = "#link-invalid";
                    msg = "Invalid payment link.";
                    icon = linkInvalidIcon;
                }
                if (response.errorCode === "TLC-1002") {
                    modalId = "#consumed-link";
                    msg = "Payment link is already consumed.";
                    icon = linkExpiredIcon;
                }
                if (response.errorCode === "TLC-1005") {
                    modalId = "#consumed-or-expired-link";
                    msg = "Payment link is either expired or already consumed.";
                    icon = linkExpiredIcon;
                }
                setErrorModalState({
                    modalId: modalId,
                    show: true,
                    content: msg,
                    icon: icon,
                    btnText: "Go to Home page",
                });
                return;
            }
            const {
                lead: { memberdetails, leadId, membershipTypeLevel },
                payment: {
                    membershipType,
                    membershipNumber,
                    consumerId,
                    amount,
                    gstNumber,
                    gstCompanyName,
                    address,
                    discount,
                    promocode,
                    transactionType,
                } = {},
            } = response.data;
            const plan = {
                Price: Number(amount),
                currencycode: "INR",
                customerSetID: membershipType ?? "",
                customersetUniqueIdentifier: "TLC_RAD_PLA",
                finalPrice: 22420,
                isPromocodeDriven: false,
                levelName: membershipTypeLevel,
                tax_1: 18,
            };
            const encryptedPlan = encryptDataFunction(plan);
            sessionStorage.setItem("plan", encryptedPlan);
            setStep("2");
            sessionStorage.setItem("step", "2");
            sessionStorage.setItem("flag", true);
            sessionStorage.setItem("memberId", consumerId);
            sessionStorage.setItem("l_id", leadId);
            sessionStorage.setItem("t_type", transactionType);
            if (membershipNumber) {
                sessionStorage.setItem("m_num", membershipNumber);
            }
            const userData = {
                email: memberdetails?.email ?? "",
                fName: memberdetails?.firstName ?? "",
                lName: memberdetails?.lastName ?? "",
                mobile: memberdetails?.mobile ?? "",
                salutation: "Mr" ?? "Mr",
            };
            const encryptedUserData = encryptDataFunction(userData);
            sessionStorage.setItem("userData", encryptedUserData);
            if (gstNumber) {
                const { addressLine1, addressLine2, state, pinCode } = address;
                const userGstData = {
                    id: "",
                    gstNum: gstNumber ?? "",
                    cName: gstCompanyName ?? "",
                    address1: addressLine1 ?? "",
                    address2: addressLine2 ?? "",
                    state: state ?? "",
                    pinCode: pinCode ?? "",
                    country: "India",
                };
                const encryptedUserGstData = encryptDataFunction(userGstData);
                sessionStorage.setItem("userGstData", encryptedUserGstData);
            }
            if (discount) {
                const paylaod = {
                    promoCode: promocode,
                    discount: Number(discount),
                };
                const encryptedPriceData = encryptDataFunction(paylaod);
                sessionStorage.setItem("d_plan", encryptedPriceData);
            }
        };
        const url = window.location.href.split("?")[1] ?? "";
        if (url) {
            const urlParams = url.split("&");
            const uId = urlParams[0]?.split("=")[1] ?? "";
            const pId = urlParams[1]?.split("=")[1] ?? "";
            if (pId === "TLC_RAD_PC") {
                sessionStorage.setItem("lnk_flg", true);
                fetchUserDetailsFromUid(uId);
            } else {
                setErrorModalState({
                    modalId: "#link-invalid",
                    show: true,
                    content: "Invalid payment link.",
                    icon: linkInvalidIcon,
                    btnText: "Go to Home page",
                });
            }
        }
    }, []);
    useEffect(() => {
        const step = sessionStorage.getItem("step");
        if (step === "2") {
            setStep(step);
        }
    }, []);
    useEffect(() => {
        const paymentLinkFlag = sessionStorage.getItem("lnk_flg");
        if (!paymentLinkFlag) {
            const updatePaymentFlag = sessionStorage.getItem("update");
            if (!plan || updatePaymentFlag) {
                navigate("/");
            }
        }
    }, [navigate, plan]);

    return (
        <>
            <Container className="buy-membership-container">
                <MembershipSteps step={step} />
                <MembershipStepsMobile step={step} />
                <div className="flex-grow-1">
                    {step === "1" && <StepOne plan={plan} setStep={setStep} />}
                    {step === "2" && <StepTwo plan={plan} setStep={setStep} />}
                </div>
            </Container>
            <CustomModal modalState={errorModalState} onClose={closeModal} />
        </>
    );
};

export default React.memo(BuyMembershipSection);
