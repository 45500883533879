import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import "./RedeemBenefitOtpSection.scss";
import OTPInput from "otp-input-react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../custom-modal/CustomModal";
import alertIcon from "../../assets/error-triangle.svg";
import successIcon from "../../assets/successicon.svg";
import { postCall } from "../../utils/AxiosInterseptor";
import { VALIDATE_CERTIFICATE_BY_OTP } from "../../utils/axiosUtil";

const RedeemBenefitOtpSection = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [errorText, setErrorText] = useState("");
    const [modalState, setModalState] = useState({
        modalId: "",
        show: false,
        content: "",
        icon: "",
        btnText: "",
    });
    const closeModal = () => {
        setModalState({
            modalId: "",
            show: false,
            content: "",
            icon: "",
            btnText: "",
        });
        if (modalState.btnText === "Homepage") {
            setOtp("");
            navigate("/");
        }
    };
    const submitOtpHandler = async () => {
        setErrorText("");
        if (otp.length !== 4) {
            setErrorText("Please enter OTP.");
            return;
        }
        const urlParams = new URLSearchParams(location.search);
        const transactionId = urlParams.get("transactionId");
        if (!transactionId) {
            setModalState({
                modalId: "invalid-transactionId",
                show: true,
                content:
                    "We seem to have a system error. Please try after some time.",
                icon: alertIcon,
                btnText: "OK",
            });
            return;
        }
        const payload = { otpFor: "requestcertificate", otp };
        const response = await postCall(
            VALIDATE_CERTIFICATE_BY_OTP(transactionId),
            payload
        );
        if (response.errorCode === "TLC-051") {
            setErrorText("Please enter a valid OTP.");
            return;
        }
        if (response.errorCode === "TLC-1153") {
            setModalState({
                modalId: "invalid-transactionId",
                show: true,
                content: "This OTP has already been successfully verified.",
                icon: alertIcon,
                btnText: "OK",
            });
            return;
        }
        if (response.msg !== "Success") {
            setModalState({
                modalId: "invalid-transactionId",
                show: true,
                content:
                    "We seem to have a system error. Please try after some time.",
                icon: alertIcon,
                btnText: "OK",
            });
            return;
        }
        setModalState({
            modalId: "success-otp-modal",
            show: true,
            content:
                "Gifted Certificate has been placed on the Membership page of your app.",
            icon: successIcon,
            btnText: "Homepage",
        });
    };
    useEffect(() => {
        setErrorText("");
    }, [otp]);
    return (
        <>
            <section className="redeem-benefit-section">
                <Container className="redeem-benefit-container">
                    <p className="heading-detail">
                        Enter OTP to confirm transfer of Prestige Club
                        Certificate. The OTP issued is valid for 15 minutes. If
                        the OTP has expired, your friend will have to request
                        for the transfer of the certificate again.
                    </p>
                    <div className="otp-inputs-wrapper">
                        <OTPInput
                            className="otp-inputs"
                            OTPLength={4}
                            otpType="number"
                            value={otp}
                            onChange={setOtp}
                        />
                        <p className="error-text">{errorText}</p>
                    </div>
                    <Button onClick={submitOtpHandler}>Submit</Button>
                </Container>
            </section>
            <CustomModal modalState={modalState} onClose={closeModal} />
        </>
    );
};

export default RedeemBenefitOtpSection;
