import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./hotelInfoSection.scss";

const HotelInfoSection = ({ heading, content, imgSrc, imgAlt }) => {
    return (
        <Container className="hotel-info-container">
            <section className="infoSection">
                <Row>
                    <Col md={6} className="textcenteraling">
                        <h1>{heading}</h1>
                        <p dangerouslySetInnerHTML={{ __html: content }} />
                    </Col>
                    <Col md={6}>
                        <img className="img-fluid" src={imgSrc} alt={imgAlt} />
                    </Col>
                </Row>
            </section>
        </Container>
    );
};

export default React.memo(HotelInfoSection);
