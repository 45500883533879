import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import OfferAndEventSection from "../components/offerAndEventSection/OfferAndEventSection";
import Footer from "../components/footer/Footer";
import HotelInfoSection from "../components/hotelInfoSection/HotelInfoSection";
import hotelImage from "../assets/jalandhar-side-image.webp";
import {
    jalandharAnnualBenefitData,
    jalandharOneTimeBenefitData,
} from "../utils/tableData";
import MemberBenefitSection from "../components/memberBenefitSection/MemberBenefitSection";
import homepageBanner from "../assets/jalandhar-banner.webp";
import homepageBannerMobile from "../assets/jalandhar-banner-mobile.webp";
import { jalandharOfferCarousalData } from "../utils/offerAndEventsData";
import { Helmet } from "react-helmet";

const JalandharHomePage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        //TO RESET STEP ONE
        if (sessionStorage.getItem("userData")) {
            sessionStorage.removeItem("step");
            sessionStorage.removeItem("d_plan");
            sessionStorage.removeItem("otp");
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("userGstData");
            sessionStorage.removeItem("flag");
            sessionStorage.removeItem("memberId");
            sessionStorage.removeItem("m_num");
            sessionStorage.removeItem("sfId");
            sessionStorage.removeItem("c_data");
            sessionStorage.removeItem("l_id");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("z_flag");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("gate_data");
            sessionStorage.removeItem("lnk_flg");
        }
    }, []);
    const jalandharPropertyTableData = [
        {
            clone: false,
            tableHeading: "Annual Benefits",
            tableData: jalandharAnnualBenefitData,
            tableScrollId: "jalandhar-annual-table-scroll",
            tableId: "jalandhar-annual-main-table",
        },
        {
            clone: false,
            tableHeading: "One Time Use Benefits",
            tableHead: "Certificate Benefits",
            tableData: jalandharOneTimeBenefitData,
            tableScrollId: "jalandhar-oneTime-table-scroll",
            tableId: "jalandhar-oneTime-main-table",
        },
    ];
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Enrol as a member of Prestige Club at Radisson Hotel Jalandhar and enjoy exclusive benefits and special offers on dining, stay and more, all year round."
                />
                <title>
                    Prestige Club | Membership of Radisson Hotel Jalandhar
                </title>
            </Helmet>
            <Header />
            <HeroSection
                src={homepageBanner}
                mobileSrc={homepageBannerMobile}
                showForm={true}
                alt="Prestige Club Radisson Hotel Jalandhar banner"
            />
            <HotelInfoSection
                content="Radisson Jalandhar provides convenient access to the bustling business district of the city. Indulge in a delectable meal at one of our three on-site restaurants, enjoy a range of convenient amenities, including complimentary Wi-Fi, a fully equipped business centre, and well-appointed meeting rooms. Unwind at our expansive outdoor pool or remain fit at our well-equipped fitness centre."
                heading="Radisson Hotel Jalandhar"
                imgSrc={hotelImage}
                imgAlt="Prestige Club Radisson Hotel Jalandhar side image"
            />
            <MemberBenefitSection
                property="jalandhar"
                propertyTableData={jalandharPropertyTableData}
                buyFlag={false}
            />
            <OfferAndEventSection carousalData={jalandharOfferCarousalData} />
            <Footer />
        </>
    );
};

export default JalandharHomePage;
