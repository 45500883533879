import React from "react";
import "./PageLoader.scss";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import loaderGif from "../../assets/payment-loader.gif";

export const PageLoader = ({ suspenseLoading }) => {
    const { isPageLoading } = useSelector((state) => state.loader);
    return (
        <>
            {(suspenseLoading ? suspenseLoading : isPageLoading) && (
                <div className="loading-backdrop">
                    <div className="loading">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
        </>
    );
};

export const PaymentLoader = () => {
    const { isPaymentLoading } = useSelector((state) => state.loader);
    return (
        <Modal id="payment-loader-modal" show={isPaymentLoading} centered>
            <Modal.Body>
                <img src={loaderGif} alt="" />
                <p>You are now being directed to a secure payment gateway</p>
            </Modal.Body>
        </Modal>
    );
};
