import cryptoJs from "crypto-js";

export const generateCharacters = (length) => {
    return Array.from({ length: length }, () =>
        String.fromCharCode(Math.floor(Math.random() * 26) + 65)
    ).join("");
};

export const generateNumbers = (length) => {
    return Array.from({ length: length }, () =>
        Math.floor(Math.random() * 10)
    ).join("");
};

export const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const DCRYPT_KEY = "agjLaIwNjGwAgaMRITfgsSHAhja";

export const encryptDataFunction = (data) => {
    return cryptoJs.AES.encrypt(JSON.stringify(data), DCRYPT_KEY).toString();
};

export const decryptDataFunction = (data) => {
    if (!data) {
        return "";
    }
    return JSON.parse(
        cryptoJs.AES.decrypt(data, DCRYPT_KEY).toString(cryptoJs.enc.Utf8)
    );
};
