import React, { useEffect } from "react";
import ThankyouSection from "../components/thankyouSection/ThankyouSection";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import Footer from "../components/footer/Footer";
import bannerImage from "../assets/banner-thankyou.webp";
import bannerImageMobile from "../assets/membership-banner-mobile.webp";
import { Helmet } from "react-helmet";

const ThankyouPage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    Prestige Club| Membership of Participating Radisson Hotels
                </title>
            </Helmet>
            <Header />
            <HeroSection src={bannerImage} mobileSrc={bannerImageMobile} />
            <ThankyouSection />
            <Footer />
        </>
    );
};

export default ThankyouPage;
