import axios from "axios";
import { useEffect } from "react";
import { generateCharacters, generateNumbers } from "./utils";
import { useDispatch } from "react-redux";
import {
    setPageLoader,
    setPaymentLoader,
} from "../redux/loader-slice/loaderSlice";

const UUID = generateCharacters(6) + generateNumbers(4);

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_MULE_END_POINT,
    headers: {
        AppID: process.env.REACT_APP_MULE_APP_ID,
        program_id: process.env.REACT_APP_MULE_PROGRAM_ID,
        subProgramID: process.env.REACT_APP_MULE_SUB_PROGRAM_ID,
        client_id: process.env.REACT_APP_MULE_CLIENT_ID,
        client_secret: process.env.REACT_APP_MULE_CLIENT_SECRET,
        language: "en",
        UUID,
        "Content-Type": "application/json",
    },
});

export const AxiosInterceptor = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const req = (request) => {
            if (
                request.url.includes("/payment") ||
                request.url.includes("/account")
            ) {
                dispatch(setPaymentLoader(true));
            } else {
                dispatch(setPageLoader(true));
            }
            return request;
        };
        const resInterceptor = (response) => {
            dispatch(setPageLoader(false));
            dispatch(setPaymentLoader(false));
            return response;
        };
        const errInterceptor = (error) => {
            dispatch(setPageLoader(false));
            dispatch(setPaymentLoader(false));
            return error;
        };
        const reqInterceptor = axiosInstance.interceptors.request.use(req);
        const interceptor = axiosInstance.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );
        return () => {
            axiosInstance.interceptors.response.eject(interceptor);
            axiosInstance.interceptors.request.eject(reqInterceptor);
        };
    }, [dispatch]);
    return children;
};

export const getCall = async (url) => {
    try {
        let response;
        if (url.includes("postalpincode")) {
            //DUE TO CORS
            response = await axios.get(url);
        } else {
            response = await axiosInstance.get(url);
        }
        if (response?.data) {
            return { msg: "Success", data: response.data };
        }
        return {
            msg: "FAIL",
            errorCode:
                response?.response?.data?.errorCode ??
                "Something went wrong. Please try again!",
        };
    } catch (error) {
        return {
            msg: "CRASH",
            errorCode:
                error?.response?.data?.errorCode ??
                "Something went wrong. Please try again!",
        };
    }
};

export const postCall = async (url, payload) => {
    try {
        const response = await axiosInstance.post(url, payload);
        if (response?.data) {
            return { msg: "Success", data: response.data };
        }
        return {
            msg: "FAIL",
            errorCode:
                response?.response?.data?.errorCode ??
                "Something went wrong. Please try again!",
        };
    } catch (error) {
        return {
            msg: "CRASH",
            errorCode:
                error?.response?.data?.errorCode ??
                "Something went wrong. Please try again!",
        };
    }
};
