import React from "react";
import "./ThankyouSection.css";
import { useNavigate } from "react-router-dom";

const ThankyouTick = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group">
                <path
                    id="Vector"
                    d="M47.6923 23.8462C47.6923 10.6763 37.016 0 23.8462 0C10.6763 0 0 10.6763 0 23.8462C0 37.016 10.6763 47.6923 23.8462 47.6923C37.016 47.6923 47.6923 37.016 47.6923 23.8462Z"
                    fill="#2C4390"
                />
                <path
                    id="Vector_2"
                    d="M31.5833 16.5886L21.6003 27.8974L16.2683 23.1167L14.5151 25.2629L20.8506 30.9478L21.8407 31.8502L22.7316 30.8429L33.5921 18.5268L31.5833 16.5886Z"
                    fill="white"
                />
            </g>
        </svg>
    );
};

const ThankyouSection = () => {
    const navigate = useNavigate();
    return (
        <section className="infoSection">
            <div className="container">
                <div className="row">
                    <div className="thankupage">
                        <div className="thankubx">
                            <div className='thankyouTick'>
                                <ThankyouTick />
                            </div>
                            <div className="thankyoutext">
                                <h3>Thank you!</h3>
                                <p>
                                    Our Membership Specialist will contact you
                                    within 24 business hours.
                                </p>
                                <button
                                    type="submit"
                                    className="homebtn"
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    Go to Home Page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default React.memo(ThankyouSection);
