import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import mobileApp from "../../assets/mobileApp.png";
import playStore from "../../assets/download_play_store.png";
import appleStore from "../../assets/download_apple_store.png";
import "./AppInfoSection.scss";
import { Link } from "react-router-dom";

const benefitsData = [
    "Easy enrolment and instant access to benefits.",
    "Quick and free sign up as a guest.",
    "Five free high-value certificates for guests.",
    "Instant reservations and personalised experiences.",
    "Customised gifting.",
    "Plan your social or business events.",
];

const AppInfoSection = () => {
    return (
        <Container className="app-info-container">
            <section className="infoSection">
                <Row>
                    <Col md={6} className="textcenteraling">
                        <h2>
                            Download the <br></br>Prestige Club App
                        </h2>
                        <p>
                            Prestige Club App allows easy enrolment and use of
                            the membership benefits.
                        </p>
                        <ul>
                            {benefitsData.map((benefit, i) => (
                                <li key={i}>{benefit}</li>
                            ))}
                        </ul>
                        <div className="app-icons d-flex gap-4 ms-4 pb-4">
                            <Link
                                to="https://apps.apple.com/in/app/prestige-club/id6447290339"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="download-app"
                                    alt="App Store tile"
                                    src={appleStore}
                                />
                            </Link>
                            <Link
                                to="https://play.google.com/store/apps/details?id=com.tlcgroup.customer.prestigeclub&pli=1"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="download-app"
                                    alt="Google Play tile"
                                    src={playStore}
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <img
                            className="img-fluid mobileview"
                            src={mobileApp}
                            alt="Prestige Club App screens"
                        />
                    </Col>
                </Row>
            </section>
        </Container>
    );
};

export default React.memo(AppInfoSection);
