export const termsAndConditions = {
    pageHeading: "Membership Terms and Conditions",
    isLi: true,
    pageData: [
        {
            heading: "1. General",
            data: [
                "Prestige Club membership program is managed by TLC DigiTech Pvt. Ltd. (www.tlcgroup.com)",
                "The program offers dining, accommodation and other privileges at participating hotels of Radisson Hotel Group.",
                "The membership term (validity period) shall be for a period of 12 calendar months from the date of the issuance of membership.",
                "The Membership Card and the Certificates have no cash value and cannot be traded.",
                "Membership is not transferable and the Member must be 18 years of age or above to join the program.",
                "The total amount of the membership fee will be refunded, provided the membership has not been used and the refund is requested by the Member within 15 days of acceptance of the membership. Refund will only be made after the receipt of the membership card, all the certificates and a written cancellation request.",
                "Member benefits cannot be used in conjunction with any other discounted offer.",
                "Prestige Club Hotels reserves the right to add, modify, withdraw or delete any of the benefits, rules, terms and conditions or the duration of the program, with or without prior notice. All decisions in this regard will be final and binding on the member of the program.",
                "Fraud or abuse concerning the program membership is subject to appropriate administrative and / or legal action, including termination of the membership.",
                "Should for any reason, the Prestige Club membership is discontinued, the Hotel shall have no liability, financial or otherwise to the Member.",
                "All taxes shall be borne by the Members or the User of the benefits and all the bills must be settled at the time of departure.",
                "All disputes will be settled by arbitration.",
                "Discounts and certificates are not applicable during Christmas, New Year's Eve and special events as decided by the Hotel or any other public holiday as specified by the Hotel.",
                "Prestige Club shall not be liable for any amount greater than the membership fee to any member as a result of his or her membership.",
                "Without prejudice to any other mode of proof, through the payment for Prestige Club, the member accepts these terms and conditions, as amended from time to time and agrees to be bound by them.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "2. Dining and Accommodation",
            data: [
                "To avail the dining discounts other than the single diner discounts, the requirement is a minimum bill amount of Rs. 500. This discount can only be availed on the total food order and only one card can be presented per table / per visit. Two membership cards cannot be presented during ths same visit.",
                "Discounts cannot be given in conjunction with any other special promotion or offer.",
                "Children above 5 years of age are included in the total guest count for the purpose of calculating discounts.",
                "A valid Membership Card must be presented to avail of discounts and benefits.",
                "Room reservations are subject to advance bookings and allocation availability. Special rates will not apply on bookings made through Travel Agents or on other promotional or corporate rates. Blackout dates for room reservations apply.",
                "Amendments or cancellations of room reservations must be made with a 72 hour prior notice.",
                "The value of the specific discount does not include any applicable taxes.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "3. Certificates",
            data: [
                "All certificates enclosed are for one time use only, must be surrendered at the time of arrival, have to be utilized in full and cannot be used in conjunction or exchanged for cash or any other service.",
                "The benefits as given in the gift certificates are accompanied by the terms and conditions given on the face and on the reverse of the certificate. The certificates are valid strictly as indicated, and cannot be extended or re-validated.",
                "Certificates used for room reservations which are not amended or cancelled even if not used cannot be used again and will result in the cancellation of the certificate.",
                "Some certificates are for members use only and some can be gifted. This is mentioned in the terms of usage of the certificate.",
                "Complimentary cake and wine will only be available at the restaurants provided a reservation has been made 48 hours in advance.",
                "In the event that the certificate(s) is / are lost, it/ these cannot be replaced or the money refunded.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "4. Referral Policy",
            data: [
                "Members may refer the Prestige Club Program to a friend or any other person and upon successful enrollment in the Prestige Club Program by such person, the referring Member will be eligible for an extra benefit. Please connect with the Prestige Club Contact Center for details.",
                "These Terms and Conditions shall apply separately and independently to each Member enrolled in the Prestige Club Program, including each person enrolled by referral from a Member.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "5. Termination of Membership",
            data: [
                "Membership will end automatically upon the death of the Member.",
                "Radisson and / or TLC may immediately, without notice or limitation, terminate any membership to the Prestige Club Program at its sole discretion without assigning any reason or if the Member: (i) violates these Terms and Conditions, the Privacy Policy, the Website Terms of Use or any other applicable additional terms, or appears to be utilizing the benefits under the Prestige Club Program in a manner inconsistent with these Terms and Conditions or the intent of the Prestige Club Program; (ii) fails to promptly pay any applicable dues; (iii) is suspected or found to be acting in an abusive or fraudulent manner or engaging in any conduct that artificially, improperly, or deceptively impacts the use of membership benefits (including, without limitation, use of any “bot,” macro, or other automated means of participating in the Prestige Club Program); (iv) attempts to transfer their account to another Member or a third party, except with the express written permission of Radisson; (v) makes any misrepresentation in connection with the Prestige Club Program; (vi) impairs the normal functioning of the Prestige Club Program website or the Prestige Club App or the Prestige Club Program; (vii) disturbs other guests or otherwise disrupts or impairs the normal operation of Radisson ; (viii) acts in a manner detrimental to us or any third party (including, without limitation, behaving towards any Radisson Hotel, employees or other guests of Radisson in any manner that is abusive, harassing, threatening, derogatory, disrespectful, lewd, obscene, offensive, or otherwise inappropriate, as determined by Radisson in its sole discretion); or (ix) acts in any manner inconsistent with applicable law; or (x) allows their membership account to remain inactive for a period of two months or more; or (xi) files for insolvency or bankruptcy, or is otherwise subject to a bankruptcy proceeding.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "6. Termination of Prestige Club Program",
            data: [
                "If the Prestige Club Program is terminated, all unredeemed benefits will be forfeited without the imposition of any obligation, refund or liability on us, unless otherwise announced by us. Upon termination, there shall be no obligation on us to offer a replacement program. Radisson and TLC reserve the right to determine in our sole discretion how to handle any benefits under the Prestige Club Program to activities occurring after the effective date of termination. Such discretion may include declining to provide such benefits after the effective date of the termination.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "7. General Disclaimer",
            data: [
                "If any Participating or Partner Hotel ceases to operate as a hotel owned, managed, operated, franchised, or licensed by Radisson, or if such hotel ceases to be a Participating Hotel or a Partner Hotel (as applicable), Members will not be eligible to redeem any benefits under the Prestige Club Program, for an otherwise qualifying activity at that property, even if the stay was booked before the hotel ceased to be associated with Radisson or ceased its participation in the Prestige Club Program.",
                "If a Program Partner ceases to be affiliated with Radisson or ceases its participation in the Prestige Club Program, you will not be eligible to redeem any benefits provided by such Program Partner, even if the benefit was requested for before such Program Partner ceased its participation in the Prestige Club Program.",
                "The use of benefits under and participation in the Prestige Club Program, including, but not limited to, the collection of certificates or other benefits may be subject to fees and taxes in the relevant jurisdiction. As a result, members will be required to pay such fees and taxes as applicable. Any tax liability, including disclosure to third parties, connected with receipt or use of any of the benefits under the Prestige Club Program is the Member’s responsibility. We are not liable for any such tax liability, duty or other charges.",
                "Any request for online payment for payment of membership fee or for the purchase of any benefit under the Prestige Club Program are transferred to and processed by a third-party payment gateway service provider. We do not store any debit/credit card information of any Member in our database or in any data backup system or retrieval system. For abundant caution, each transaction done through the Payment Gateway has a limit of INR 50,000 (Indian Rupees fifty thousand only). The Prestige Club Parties specifically disclaim all warranties and conditions of any kind, whether express or implied, in relation to the operation of the payment gateway, and shall not be liable for any monetary or other damage suffered by any person due to (i) any delay, failure, interruption, or corruption of any data or other information transmitted in connection with the use of the payment gateway or any services in such connection; and/or (ii) any interruption or errors in the operation of the payment gateway.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "8. No Warranties Limitation of Liability",
            data: [
                "The Prestige Club Program is made available to you on an as-is, as-available basis, without representations or warranties of any kind, whether express or implied. Prestige Club Parties make no representation about the suitability of the Prestige Club Program for you. The Prestige Club Parties specifically disclaim all warranties and conditions of any kind, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.",
                "Your participation in the Prestige Club Program is voluntary, by your own choice and at your own risk. The Prestige Club Parties do not warrant or represent that your access to the Prestige Club App will be uninterrupted or error-free or that any information, data, content or other material accessible through the Prestige Club App will be free of viruses, bugs, malwares or other harmful components that may interfere with or damage the operation, security or functionality of your device(s).",
                "Without limiting the foregoing, the Prestige Club Parties do not represent or warrant that the information related to the Prestige Club Program is accurate, complete, reliable, useful, timely, or current or that the Prestige Club Program will operate without interruption or error. Despite commercially reasonable efforts the Prestige Club App, website and other materials may contain typographical or other errors or inaccuracies, including, without limitation, regarding the benefits of the Prestige Club Program. No Prestige Club Party will be responsible for any such error, typo, or misprint in these terms or any such program materials. The Prestige Club Parties reserve the right to void or refuse to process any benefit or transaction arising out of such an error, typo, or misprint.",
                "The materials on the Prestige Club are for general information only and nothing in the Prestige Club contains professional advice or any binding commitment in relation to the accuracy, reliability or availability of the products and services on offer.",
                "The Prestige Club Parties will not be liable for any acts, errors or omissions of any other Member, third party or program participant or any third party’s loyalty or rewards program.",
                "In no event will a Prestige Club Party be liable to you for any indirect, incidental, special or consequential damages arising out of or in any way connected with the Prestige Club Program. You agree that the Prestige Club Parties’ total cumulative liability in connection with or arising out of the Prestige Club Program and these Terms and Conditions, will not exceed the amount paid by you directly as membership fee towards your participation in the Prestige Club Program.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "9. Indemnification",
            data: [
                "You agree to indemnify, defend and hold Prestige Club Parties, harmless from and against any and all claims, judgment, deficiencies, damages, liabilities, losses, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly out of or from (a) a breach of these Terms and Conditions, the Website Terms of Use or the Privacy Policy; (b) any allegation that any information or other materials submitted to us infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; and/or (c) the use of the benefits under the Prestige Club Program, the website or any related product or service by any Member.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "10. Binding Decisions",
            data: [
                "All decisions by us, where applicable in relation to the Prestige Club Program, including the benefits offered under the Prestige Club Program, the conditions of membership, termination of membership, membership accounts on the Prestige Club App, or closure or withdrawal of benefits are final and binding on all members and applicants.",
                "All interpretations of the Terms and Conditions are at our sole discretion, where applicable, and our decision will be final and binding. If the English version of these Terms and Conditions conflicts with any other language version, the English version shall prevail.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "11. Governing Law",
            data: [
                "The Prestige Club Program and these Terms and Conditions are governed by the substantive laws of India.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "12. Dispute Resolution",
            data: [
                "Any disputes or controversy arising out of or relating to these Terms and Conditions shall be settled by arbitration to be held in New Delhi in accordance with the Arbitration and Conciliation Act, 1996 as amended, in the English language, and shall be heard and determined by an arbitral tribunal composed of a sole arbitrator appointed by agreement between the parties to the dispute hereto. If the parties fail to reach an agreement with respect to the appointment of an arbitrator within 30 calendar days of any party’s notice to the other party of the existence of a dispute, the parties shall appoint one arbitrator each and the arbitrators appointed by the parties shall appoint a third presiding arbitrator. The decision of the arbitrator(s) shall be final, conclusive and binding on the parties. Notwithstanding the foregoing, however, nothing contained herein shall be deemed to prevent either party from seeking and obtaining injunctive and equitable relief from any court of competent jurisdiction without the posting of any bond or other security.",
                "Subject to the above, each of the Parties submits to the exclusive jurisdiction of the courts at New Delhi.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "13. Program Contact Centre",
            data: [
                "All members may request assistance from the dedicated program contact center. The operating hours for the program contact are from 9:30 AM to 6:30 PM, Monday through Saturday. Members may also direct any queries regarding the Prestige Club Program by telephone at +91 11 46644600 or by e-mail at contactus@prestigeclub.in. ",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
    ],
};

export const appTermsAndConditions = {
    pageHeading: "Prestige Club App Terms and Conditions",
    isLi: false,
    pageData: [
        {
            heading: "1. General",
            data: [
                "‘Prestige Club’ is a membership program that offers dining, accommodation and spa privileges, access to a range of select hotel facilities, promotions, offers and reservations to individuals at Participating Hotels (defined below) and certain benefits from Program Partners (defined below) of Radisson Hotel Group in India (the “Prestige Club Program”). The services under the Prestige Club Program are available through the Prestige Club Mobile Application, available on iOS and Android operating systems (the “Prestige Club App”) which also provides digital membership cards and certificates.",
                "Prestige Club Program as offered by the Prestige Club App is subject to the terms and conditions of use set out herein below (the “Terms and Conditions”) and the other terms stated at the time of use of the benefits and the Privacy Policy available at https://tlcgroup.com/privacy-policy  (the “Privacy Policy”), the ‘Licensed Application End User License Agreement’ of Apple, Inc., available at https://www.apple.com/legal/internet-services/itunes/dev/stdeula/ (“Apple EULA”) and, if applicable, the end user license agreement in relation to the Google Play Store of Google LLC (”Developer distribution agreement) https://play.google.com/about/developer-distribution-agreement.html.  The Prestige Club Program website, operated by or on behalf of Radisson Hotel Group (defined below) is governed by certain terms of use available at https://www.prestigeclub.in/ (“Website Terms of Use”). Please refer to https://www.prestigeclub.in/ for the latest updates on the Prestige Club Program, including the Terms and Conditions, Website Terms of Use, the Privacy Policy, the Participating Hotels, and Program Partners. In the event of conflict between the provisions of the Apple EULA and the Terms and Conditions, the provisions of the Terms and Conditions will prevail. In the event of conflict between the provisions of the Google EULA and the Terms and Conditions, the provisions of the Terms and Conditions will prevail.",
                "By clicking “I AGREE” or by paying the membership fee or using the benefits of the membership or any feature of the Prestige Club App, you will be deemed to have read, understood and accepted the Terms and Conditions of membership, the use of the Prestige Club App, the Apple EULA, the Google Developer Distribution Agreement, the Privacy Policy and the Website Terms of Use. If the Apple EULA, Google Developer Distribution Agreement, Privacy Policy, the Website Terms of Use or the Terms and Conditions are not acceptable to you, you may exit and refrain from using any feature of the Prestige Club Program and the Prestige Club App.",
                "By accepting the Terms and Conditions, you will be entering into a binding agreement with Radisson Hotel Group and their respective affiliates, subsidiaries, associates and group companies (“Radisson”), TLC DigiTech Private Limited and its affiliates, subsidiaries, associates and group companies (“TLC”), the hotels, resorts and properties participating in the Prestige Club Program and offering all membership benefits, including sale of the Prestige Club Program memberships (the “Participating Hotels”) . For a complete list of Participating Hotels, and Program Partners, see www.prestigeclub.in for the use of the Prestige Club Program.",
                "The use of “we” or “us” in the Terms and Conditions refers to Prestige Club and TLC, Radisson Hotel Group and the Participating Hotels and Partner Hotels.",
                "The benefits, rules, Terms and Conditions, Apple EULA, Google Developer distribution agreement, Privacy Policy, the Website Terms of Use and the duration of the Prestige Club Program may be modified, withdrawn, added or deleted, with or without prior notice. All decisions taken by us in this regard shall be final and binding on the members of the Prestige Club Program.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "2. Eligibility Enrolment and Membership",
            data: [
                "The Prestige Club Program is open only for individuals, and its services may not be availed by employees of Radisson and TLC, companies, organizations, associations, travel agencies etc. unless otherwise expressly authorized by Radisson Hotel Group or TLC. Radisson and TLC reserve the right to deny membership to any individual without assigning a reason.",
                "The Prestige Club Program is open only to individuals aged 18 years and above or the age of majority where they live, and who are otherwise competent to contract as per the Indian Contract Act, 1872, as amended. Applicants are responsible for compliance with all applicable laws.",
                "By enrolling and participating in the Prestige Club Program, you agree that we may contact you by telephone, by text message, by electronic mail, by instantaneous chat, or by any other media for which you have provided contact information in connection with your membership in accordance with the terms of our Privacy Policy.",
                "Applicants can enroll as a Member and existing Members can renew their membership by creating an account on the Prestige Club App. Such applicants and existing members will be required to provide valid, accurate, complete and updated personal information on a mandatory basis, which only includes their first name, last name, mobile number and e-mail ID to avail of the services under the Prestige Club Program. Applicants will be required to complete the sign up process on the Prestige Club App. This information will be stored and used in accordance with the Privacy Policy. We reserve the right to cancel or deny access to any account found to contain false, fictitious, erroneous or outdated information.",
                "Members are advised to avail a single membership which will be reflected in the Member’s Cards Wallet on the Prestige Club App. An individual Member will have a single unique profile in the Prestige Club App. It is recommended that the Member upload a profile photograph to their profile for a better in-hotel experience. Such photograph should be of the individual Member only. Photographs of non-members, objects or locations are not permitted.",
                "Membership benefits may be availed only by Members and are accessible only through a single phone device registered by them at the time of creating their account. This device can be automatically changed by the Member once through the Prestige Club App, and through the Program Contact Centre for any subsequent changes. If a Member inadvertently creates a duplicate account by using different or alternate contact details, the details provided in the new account may be merged and consolidated into their previous account.",
                "If permitted, Members can add a Spouse membership, Partner membership, gift a membership, refer a Member or gift a certificate benefit which is transferable. All such transactions will be undertaken through the Prestige Club App and the beneficiary will be required to download the Prestige Club App, complete the sign up process and accept all the terms of usage as defined here. Spouse Members will have access to the Primary Members certificates which can be used only once by either the Primary Member or the Spouse Member.",
                "If you wish to avail input tax credit on your membership fee under the Good and Services Tax law applicable in India (“GST”), we request you to provide us with your GST registration number and the company name at the time of making the payment on the Prestige Club App or within one week of an offline purchase of the membership to the Prestige Club Program. These details will need to be updated in the personal profile of the Prestige Club App.",
                "Members are responsible for maintaining the confidentiality of the information provided by them on the Prestige Club App and for the security of information on the Prestige Club App. Members will receive a text message (SMS) and/or e-mail notification of each transaction on the Prestige Club App which they are required to authorize through a one-time password. We are not responsible for any unauthorized access to the Member’s account or any unauthorized redemption from the Member’s account. Members must immediately report any fraudulent activity on the Prestige Club App to the Prestige Club Program Contact Centre.",
                "The Prestige Club App should not be used for commercial purposes, unlawful, immoral or illegal purposes or for any purpose other than those stated in the Terms and Conditions. We reserve the right to deny access to the Prestige Club App to any user who violates the Terms and Conditions, the Website Terms of Use and the Privacy Policy, or at our sole discretion.",
                "Members must not engage in any conduct or do anything that would damage, impair or interfere with the operation or security of the Prestige Club App, the system hosting the Prestige Club App, or any of our computer systems.",
                "Members are requested to keep the Prestige Club Mobile App updated on version updates to ensure a seamless experience and continuity of all the benefits.",
                "By accepting the terms and conditions of the Prestige Club membership program and consent to the collection, use, and processing of your personal data as detailed above and in the TLC Privacy Statement, you understand that your personal data may be transferred to Radisson and handled by in accordance with the Radisson Hotel Group Privacy Statement.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "3. Membership Card and Certificates",
            data: [
                "Membership card and certificates will be issued digitally to the Member through the Prestige Club App and will be valid for the period as specified from the date of their issuance. Membership may be renewed through the Prestige Club App after the expiry period.",
                "All benefits through the use of digital certificates are for one-time use only and must be redeemed by the Member on the Prestige Club App, subject to an OTP verification if prompted. In addition, Members may be requested to present a valid photo ID at the time of redemption to confirm identity. All confirmed redemptions are deemed final to which no refunds or changes may be made.",
                "The benefits given in the certificates are accompanied by terms given on the face and on the reverse of the certificate which can be accessed by tapping on the reverse of the certificate in the Prestige Club App. Members must familiarize themselves and accept these terms before redeeming a certificate benefit.",
                "Membership certificates have no cash value and cannot be traded for any other service. Certain kinds of certificates may be gifted to another individual through the Prestige Club App, as stated on the individual certificate. Such Gift Certificates can only be retrieved by the recipient of the gift by downloading the Prestige Club App and accepting these Terms and Conditions.",
                "All certificates have to be utilized in full and cannot be exchanged for cash or any other service.",
                "In the event a Member makes a reservation through the Prestige Club Application, such Member is required to redeem, cancel or modify the service reserved solely through the Prestige Club App. Once the certificate has been used for reservation of a service, it cannot be used for any other reservation until the original reservation has been redeemed, cancelled or modified. In an event of a no-show for a reservation, the certificate will be considered to have been redeemed.",
                "A Member must ensure that the redemption is valid for that visit or else there may be a risk of losing that one-time benefit. Redemption of benefit must be done only after the Member has arrived at the Hotel.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "4. Intellectual Property",
            data: [
                "The Prestige Club App contains proprietary information, content and materials, including but not limited to domain name, icons, logos, trade names, trade secrets and trademarks in relation to the Radisson brand (Radisson Materials) that are owned by Radisson Hotel Group and is protected by applicable intellectual property and other laws. No license is granted in respect of those intellectual property rights. The use of the Prestige Club App by any person does not grant, transfer to or confer upon such person any right, title or interest in the Radisson Materials.",
                "The Prestige Club App also contains proprietary information, content and materials, including but not limited to designs, flows, copyright, trademarks, patents, software codes, source code and other underlying technology and user interface and user journey (the “TLC Materials”), that are owned by TLC and are protected by applicable intellectual property and other laws. No license is granted in respect of those intellectual property rights. The use of the Prestige Club App by any person does not grant, transfer to or confer upon such person any right, title or interest in the TLC Materials.",
                "Users agree that they will not use the Radisson Materials and TLC Materials (collectively, the “Intellectual Property”) in any way whatsoever except for use of the services available on the Prestige Club App in compliance with the Terms and Conditions. Users must not use, copy, modify, publish, reproduce, distribute, re-transmit, transfer, upload, download, sell, license, reverse engineer, decompile, or disassemble the Intellectual Property, without the prior written consent of Radisson or TLC (as applicable). Users must also not engage in any conduct that infringes, violates or misappropriates any Intellectual Property rights of Radisson, TLC or any other party, and such infringement, violation or misappropriation shall amount to a breach of the Terms and Conditions.",
                "Radisson and TLC reserve the right to take any administrative and/or legal action in the event of misuse, infringement, violation or misappropriation of the Intellectual Property by any users. You agree to indemnify, defend and hold Radisson, TLC and our affiliates, subsidiaries, directors, officers, employees, consultants, agents and other representatives, harmless from and against any and all claims, judgment, deficiencies, damages, liabilities, losses, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly out of or from an infringement or misuse of the Intellectual Property.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "5. Program Partners",
            data: [
                "Program Partners are certain third-party entities that provide benefits to members of the Prestige Club Program from time to time (“Program Partners”). By enrolling and participating in the Prestige Club Program, members agree that TLC may communicate offers from Program Partners to members on the Prestige Club App by telephone, by text message, by electronic mail, by instantaneous chat, or by any other media for which members have provided contact information in connection with their membership.",
                "Offers from Program Partners are subject to requirements under separate terms and conditions of each Program Partner. Members are responsible for directly checking and complying with a Program Partner’s terms, conditions, and privacy and usage policies. Nothing in the Terms and Conditions is intended to or shall create or establish any agency, partnership or joint venture relationship between us and the Program Partners.",
                "Program Partners may add, modify, withdraw or delete any of the benefits offered by them under the Prestige Club Program, with or without prior notice. All decisions in this regard shall be final and binding on the members of the Prestige Club Program.",
                "TLC, Radisson, Participating Hotels, Program Partners and their respective affiliates, subsidiaries and the owners, licensors, suppliers, advertisers, sponsors, operators, franchisees, directors, officers, managers, employees, consultants, agents, and other representatives (collectively, the “Prestige Club Parties”) (i) make no guarantees, warranties, or representations of any kind, express or implied, with respect to benefits, services, products, items or merchandise of Program Partners, and (ii) assume no liability for changes in or discontinuances of the terms and conditions, privacy and usage policies, products or services of the Program Partners program or other vendor's loyalty program.",
                "Prestige Club Parties shall not be liable for any expense, damages, losses, liabilities or costs (including, without limitation, attorneys’ or other legal fees), accident or inconvenience that may arise in connection with (i) the use of benefits, services, products, items or merchandise of Program Partners or (ii) the discontinuation of a Program Partner’s loyalty program, or (iii) as a result of any defence or failure of such items, or (iv) as a result of any Member’s participation in a Program Partner’s loyalty program. Any implied warranties of merchantability or fitness for a particular purpose are specifically disclaimed.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "6. Benefits",
            data: [
                "The benefits of the Prestige Club Program may be availed through paid membership. Certain features may be availed without a paid membership where Members may freely download and use the Prestige Club App. Membership benefits may vary, depending on the hotel where the membership is purchased and used or when the Prestige Club App is provided by a Program Partner or a third-party to its customers or used by a Member without a paid membership.",
                "The benefits on the Prestige Club Program are offered solely at the discretion of Radisson Hotel Group, the Participating Hotels, the Partner Hotels and Program Partners. Membership benefits are subject to availability and may be changed without prior notice and we will not be liable to the Member for any compensation in the event of denial of such benefits or services.",
                "Benefits provided under the Prestige Club Program are available at select hotels of Radisson (a list of participating properties is available at www.prestigeclub.in).",
                "Dress codes and child policy may apply at certain hotels. Liquor would be served only to guests who have attained the minimum legal age for purchase and/or consumption of alcohol in accordance with applicable law.",
            ],
            isSubHeading: true,
            isSubLi: false,
            subHeadingData: [
                {
                    subHeading: "DINING BENEFITS",
                    subData: [
                        "Dining benefits may be availed under the Prestige Club Program (described more fully at www.prestigeclub.in).  Members may make table reservations to selected restaurants at the Participating Hotels through the Prestige Club App. We will not be liable to the Member in the event a table reservation is cancelled, or any dining benefits are denied.",
                        "Dining benefits providing the specified discount on the food and beverages bill are available on a table of up to a maximum of 20 guests while dining at designated restaurants of the hotel from where the membership is purchased.",
                        "Eligible purchases of dining benefits made under the Prestige Club Program exclude (i) service charges and taxes; (ii) purchases made during the blackout dates (iii) catering and/or event purchases; (iv) carry-out and/or delivery purchases or room service orders; and (v) special promotions, discounts or other bundled food and/or beverage purchases. Radisson shall determine in their sole discretion whether a purchase constitutes an eligible purchase, and the Member agrees to abide by, and to be bound by, such determination.",
                    ],
                },
                {
                    subHeading: "ACCOMMODATION BENEFITS",
                    isSubHeading: true,
                    subData: [],
                    isSubLi: false,
                    subHeadingData: [
                        {
                            subHeading: "Reservations",
                            subData: [
                                "By using the Prestige Club App to make reservations at a Participating Hotel, you (i) agree to be bound by all of such Hotel’s standard terms and conditions and by any other policies, terms and conditions that may apply, and (ii) authorize Radisson, to use your personal information for the purpose of the reservation.",
                                "All reservations are subject to room allocation availability at Participating Hotel. It is recommended to make a reservation in advance on the Prestige Club App clearly selecting the membership benefits to be redeemed and the Hotel to avoid any disappointment on arrival. All reservations under the Prestige Club Program are non-commissionable.",
                                "Accommodation benefits are in the form of certificate benefits that are for one time use with the certificate clearly specifying the discount percentage and the hotels where those can be used. Card Benefits are applicable at hotels as indicated.",
                                "Redemption of certificates or promo codes in relation to reservations can only be made 30 days prior to the date of arrival or as stated on the certificates or terms and conditions applicable to a promo code.",
                                "Early check-in, late check-out or specific room type requests are subject to availability and cannot be guaranteed at the time of reservation.",
                                "The Hotel reserves the right to require proper proof of identity (e.g. passports or national identity cards) from any person checking in. The Hotel has the absolute discretion to refuse to check-in any guest who is unable to provide proper proof of identity.",
                                "Eligible purchases of accommodation benefits made under the Prestige Club Program exclude (i) service charges and taxes; (ii) purchases made during the blackout dates  or any other day as specified by the Hotel; (iii) purchases for specific events such as weddings, conferences, or special events; (iv) purchases made in conjunction with other prevailing offers, special promotions or discount programs; and (v) bookings and reservations made for the purposes of reselling, advertising or marketing the reservations (whether online or otherwise) to third parties including by online agents, third-party owned websites, corporations or travel agents for commission. Radisson shall determine in their sole discretion whether a purchase constitutes an eligible purchase, and the Member agrees to abide by, and to be bound by, such determination.",
                            ],
                        },
                        {
                            subHeading: "Cancellation Policy",
                            subData: [
                                "The cancellation of any reservation may vary depending on the room rates, occupancy levels, availability or other requirements of the Participating Hotels. The cancellation policy of the participating hotel will typically be provided along with the confirmation of the reservation. Any change or cancellation of a reservation made through the Prestige Club App must comply with such cancellation policy applicable to the reservation.",
                                "Notwithstanding the foregoing, it is our standard policy that any amendments to, or cancellations of, any reservation may be subject to a certain retention amount in accordance with the standard cancellation terms of the Radisson.",
                            ],
                        },
                    ],
                },
                {
                    subHeading: "SPA, HEALTH CLUB AND POOL FACILITIES",
                    subData: [
                        "These benefits are available at the hotel with restricted number of guests, on available days and time. Members are advised to read the terms and conditions of the benefit on the Prestige Club website and on the face and reverse of the certificate to avail such benefits.",
                        "These benefits are subject to the house rules that may apply for the use of these facilities. We will not be liable to the Member in the event access to spa services, health club and pool facilities is cancelled or denied for reservations made on the Prestige Club App.",
                        "Eligible purchases in relation to (i) spa benefits include spa and/or salon services, spa and/or salon retail purchases, and spa and/or salon food and beverage items; (ii) health club benefits include access to the health club, health club retail purchases, and shower, steam and sauna; and (ii) pool facilities include access to the pool, shower, steam and sauna and/or food and beverage items.",
                        "Eligible purchases of spa, health club and pool benefits made under the Prestige Club Program exclude (i) service charges and taxes; (ii) purchases made during the blackout dates or any other day as specified by the Radisson ; (iii) purchases for specific events such as weddings, conferences, or special events; (iv) purchases made in conjunction with other prevailing offers, special promotions or discount programs; and (v) bookings and reservations made for the purposes of reselling, advertising or marketing the reservations (whether online or otherwise) to third parties.  Radisson shall determine in their sole discretion whether a purchase constitutes an eligible purchase, and the Member agrees to abide by, and to be bound by, such determination.",
                    ],
                },
            ],
        },
        {
            heading: "7. Referral Policy",
            data: [
                "Members may refer the Prestige Club Program to a friend or any other person and upon successful enrollment in the Prestige Club Program by such person, the referring Member will be eligible for an extra benefit.  Please connect with the Prestige Club Contact Center for details. ",
                "These Terms and Conditions shall apply separately and independently to each Member enrolled in the Prestige Club Program, including each person enrolled by referral from a Member.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "8. Cancellation or Termination of Membership",
            data: [],
            isSubHeading: true,
            isSubLi: false,
            subHeadingData: [
                {
                    subHeading: "CANCELLATION",
                    subData: [
                        "Members who wish to discontinue their membership are required to submit a written request of cancellation and refund (if applicable) within 15 days of becoming a Member. Upon submission of a written request, termination is irreversible. The membership account of the concerned Member on the Prestige Club App will be closed, and the total amount of the membership fee will be refunded provided that no benefits under the Prestige Club Program have been used by the Member and the refund requested adheres to the given timeline. The refund will be processed upon the receipt of membership card or certificates, if any and the written request for cancellation of membership.",
                    ],
                },
                {
                    subHeading: "TERMINATION",
                    subData: [
                        "Membership will end automatically upon the death of the Member.",
                        "Radisson and / or TLC  may immediately, without notice or limitation, terminate any membership to the Prestige Club Program at its sole discretion without assigning any reason or if the Member: (i) violates these Terms and Conditions, the Privacy Policy, the Website Terms of Use or any other applicable additional terms, or appears to be utilizing the benefits under the Prestige Club Program in a manner inconsistent with these Terms and Conditions or the intent of the Prestige Club Program; (ii) fails to promptly pay any applicable dues; (iii) is suspected or found to be acting in an abusive or fraudulent manner or engaging in any conduct that artificially, improperly, or deceptively impacts the use of membership benefits (including, without limitation, use of any “bot,” macro, or other automated means of participating in the Prestige Club Program); (iv) attempts to transfer their account to another Member or a third party, except with the express written permission of Radisson; (v) makes any misrepresentation in connection with the Prestige Club Program; (vi) impairs the normal functioning of the Prestige Club Program website or the Prestige Club App or the Prestige Club Program; (vii) disturbs other guests or otherwise disrupts or impairs the normal operation of Radisson ; (viii) acts in a manner detrimental to us or any third party (including, without limitation, behaving towards any Radisson Hotel, employees or other guests of Radisson  in any manner that is abusive, harassing, threatening, derogatory, disrespectful, lewd, obscene, offensive, or otherwise inappropriate, as determined by Radisson in its sole discretion); or (ix) acts in any manner inconsistent with applicable law; or (x) allows their membership account to remain inactive for a period of two months or more; or (xi) files for insolvency or bankruptcy, or is otherwise subject to a bankruptcy proceeding.",
                    ],
                },
            ],
        },
        {
            heading: "9. Termination of Prestige Club Program",
            data: [
                "If the Prestige Club Program is terminated, all unredeemed benefits will be forfeited without the imposition of any obligation, refund or liability on us, unless otherwise announced by us. Upon termination, there shall be no obligation on us to offer a replacement program. Radisson and TLC reserve the right to determine in our sole discretion how to handle any benefits under the Prestige Club Program to activities occurring after the effective date of termination. Such discretion may include declining to provide such benefits after the effective date of the termination.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "10. General Disclaimer",
            data: [
                "If any Participating or Partner Hotel  ceases to operate as a hotel owned, managed, operated, franchised, or licensed by Radisson, or if such hotel ceases to be a Participating Hotel or a Partner Hotel (as applicable), Members  will not be eligible to redeem any benefits under the Prestige Club Program, for an otherwise qualifying activity at that property, even if the stay was booked before the hotel ceased to be associated with Radisson  or ceased its participation in the Prestige Club Program.",
                "If a Program Partner ceases to be affiliated with Radisson or ceases its participation in the Prestige Club Program, you will not be eligible to redeem any benefits provided by such Program Partner, even if the benefit was requested for before such Program Partner ceased its participation in the Prestige Club Program.",
                "The use of benefits under and participation in the Prestige Club Program, including, but not limited to, the collection of certificates or other benefits may be subject to fees and taxes in the relevant jurisdiction. As a result, members will be required to pay such fees and taxes as applicable. Any tax liability, including disclosure to third parties, connected with receipt or use of any of the benefits under the Prestige Club Program is the Member’s responsibility. We are not liable for any such tax liability, duty or other charges.",
                "Any request for online payment for payment of membership fee or for the purchase of any benefit under the Prestige Club Program are transferred to and processed by a third-party payment gateway service provider. We do not store any debit/credit card information of any Member in our database or in any data backup system or retrieval system. For abundant caution, each transaction done through the Payment Gateway has a limit of INR 50,000 (Indian Rupees fifty thousand only). The Prestige Club Parties specifically disclaim all warranties and conditions of any kind, whether express or implied, in relation to the operation of the payment gateway, and shall not be liable for any monetary or other damage suffered by any person due to (i) any delay, failure, interruption, or corruption of any data or other information transmitted in connection with the use of the payment gateway or any services in such connection; and/or (ii) any interruption or errors in the operation of the payment gateway.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "11. No Warranties Limitation of Liability",
            data: [
                "The Prestige Club Program is made available to you on an as-is, as-available basis, without representations or warranties of any kind, whether express or implied. Prestige Club Parties make no representation about the suitability of the Prestige Club Program for you. The Prestige Club Parties specifically disclaim all warranties and conditions of any kind, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.",
                "Your participation in the Prestige Club Program is voluntary, by your own choice and at your own risk. The Prestige Club Parties do not warrant or represent that your access to the Prestige Club App will be uninterrupted or error-free or that any information, data, content or other material accessible through the Prestige Club App will be free of viruses, bugs, malwares or other harmful components that may interfere with or damage the operation, security or functionality of your device(s).",
                "Without limiting the foregoing, the Prestige Club Parties do not represent or warrant that the information related to the Prestige Club Program is accurate, complete, reliable, useful, timely, or current or that the Prestige Club Program will operate without interruption or error. Despite commercially reasonable efforts the Prestige Club App, website and other materials may contain typographical or other errors or inaccuracies, including, without limitation, regarding the benefits of the Prestige Club Program. No Prestige Club Party will be responsible for any such error, typo, or misprint in these terms or any such program materials. The Prestige Club Parties reserve the right to void or refuse to process any benefit or transaction arising out of such an error, typo, or misprint.",
                "The materials on the Prestige Club App are for general information only and nothing in the Prestige Club App contains professional advice or any binding commitment in relation to the accuracy, reliability or availability of the products and services on offer.",
                "You acknowledge that your access to the Prestige Club App and the ability to redeem the benefits of the Prestige Club Program are only through your device(s) and depends on the mobile network carrier or internet. You agree that the Prestige Club Parties are not responsible for any delays, interruptions or suspension of the services available through the Prestige Club App which are caused by the failure of network connectivity. You agree to bear the expenses in respect of maintaining network connectivity to use the Prestige Club App. You also agree that Prestige Club Parties are not responsible for any malfunctions, failures, difficulties, or lost, stolen or misdirected messages, transmissions or entries, or the security of any communications entered into by you. To the fullest extent permitted by law, the Prestige Club Parties shall not be responsible for injury, loss or damage to your device(s), or interception, use or disclosure of confidential information (such as credit/debit card information or other personally identifiable information), arising out of your use of the Prestige Club App or any sites linked or associated with the Prestige Club App.",
                "The Prestige Club Parties will not be liable for any acts, errors or omissions of any other Member, third party or program participant or any third party’s loyalty or rewards program.",
                "In no event will a Prestige Club Party be liable to you for any indirect, incidental, special or consequential damages arising out of or in any way connected with the Prestige Club Program. You agree that the Prestige Club Parties’ total cumulative liability in connection with or arising out of the Prestige Club Program and these Terms and Conditions, will not exceed the amount paid by you directly as membership fee towards your participation in the Prestige Club Program.",
                "This Part 11 will survive the termination of the Prestige Club Program and your participation in the Prestige Club Program.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "12. Indemnification",
            data: [
                "You agree to indemnify, defend and hold Prestige Club Parties, harmless from and against any and all claims, judgment, deficiencies, damages, liabilities, losses, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly out of or from (a) a breach of these Terms and Conditions, the Website Terms of Use or the Privacy Policy; (b) any allegation that any information or other materials submitted to us infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; and/or (c) the use of the benefits under the Prestige Club Program, the website or any related product or service by any Member.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "13. Binding Decisions",
            data: [
                "All decisions by us, where applicable in relation to the Prestige Club Program, including the benefits offered under the Prestige Club Program, the conditions of membership, termination of membership, membership accounts on the Prestige Club App, or closure or withdrawal of benefits are final and binding on all members and applicants.",
                "All interpretations of the Terms and Conditions are at our sole discretion, where applicable, and our decision will be final and binding. If the English version of these Terms and Conditions conflicts with any other language version, the English version shall prevail.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "14. Governing Law",
            data: [
                "The Prestige Club Program and these Terms and Conditions are governed by the substantive laws of India.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "15. Dispute Resolution",
            data: [
                "Any disputes or controversy arising out of or relating to these Terms and Conditions shall be settled by arbitration to be held in New Delhi in accordance with the Arbitration and Conciliation Act, 1996 as amended, in the English language, and shall be heard and determined by an arbitral tribunal composed of a sole arbitrator appointed by agreement between the parties to the dispute hereto. If the parties fail to reach an agreement with respect to the appointment of an arbitrator within 30 calendar days of any party’s notice to the other party of the existence of a dispute, the parties shall appoint one arbitrator each and the arbitrators appointed by the parties shall appoint a third presiding arbitrator. The decision of the arbitrator(s) shall be final, conclusive and binding on the parties. Notwithstanding the foregoing, however, nothing contained herein shall be deemed to prevent either party from seeking and obtaining injunctive and equitable relief from any court of competent jurisdiction without the posting of any bond or other security.",
                "Subject to the above, each of the Parties submits to the exclusive jurisdiction of the courts at New Delhi.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "16. Program Contact Centre",
            data: [
                "All members may request assistance from the dedicated program contact center. The operating hours for the program contact center are from 9:30 AM to 6:30 PM, Monday through Saturday. Members may also direct any queries regarding the Prestige Club Program by telephone at +91 11 46644600 or by e-mail at contactus@prestigeclub.in. The Prestige Club App also supports chat and voice options.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
    ],
};

export const disclaimer = {
    pageHeading: "Disclaimer and Terms of Use",
    isLi: true,
    pageData: [
        {
            heading: "1. Copyrights",
            data: [
                "All content and functionality on the Site, including text and TLC® logos are the exclusive property of TLC® and protected by Indian copyright laws. All rights are reserved.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "2. Trademarks",
            data: [
                "The trademarks, service marks, designs of the TLC® logo (collectively called the “Trademarks”) displayed on the Site are the registered Trademarks of TLC®. You agree that you will not refer to or attribute any information to TLC® in any public medium or for the purpose of informing or influencing any third party and that you will not use any Trademark to imply any endorsement by or relationship with TLC®",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "3. Use of Site Content",
            data: [
                "TLC® grants you a non-exclusive, non-transferable license for the term hereof to access and download, display, and print one copy of the content and functionality displayed on the Site (the “Site Content”) on any single computer solely for your internal, business use, provided that you do not modify the Site Content in any way and that you retain all copyright and other proprietary notices displayed on the Site Content. You may not otherwise reproduce, modify, distribute, transmit, post, or disclose the Site Content without prior written consent from TLC®. You may also not interrupt or attempt to interrupt the operation of this Site in any way, post any obscene, defamatory and annoying material on the Site or obscure any material already posted on the Site.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "4. Notice of Infringement",
            data: [
                "TLC® prohibits the posting of any information that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity. If you believe that your intellectual property right is infringed by any content on the Site, please write to TLC® Corporate Office address at 501 Salcon Aurum, Jasola District Center, New Delhi 110 076 for our review.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "5. Disclaimers",
            data: [
                "The content and functionality on the Site is provided with the understanding that TLC ® is not herein engaged in rendering professional advice and services to you. All content and functionality on the site is provided “as is,” without warranty of any kind, either express or implied, including, without limitation, implied warranties of merchantability and fitness for a particular purpose. TLC ® shall not be liable for any indirect, incidental, consequential, or punitive damages or for lost revenues or profits, whether or not advised of the possibility of such damages or losses and regardless of the theory of liability.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "6. Governing Law; Jurisdiction",
            data: [
                "These Terms are governed by the laws of India and the jurisdiction of the Courts of New Delhi.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
    ],
};

export const websiteUse = {
    pageHeading: "Website Terms of Use",
    isLi: true,
    pageData: [
        {
            heading: "1. General",
            data: [
                "These terms of use of the website offering information and access to the benefits under the Prestige Club Program (defined below) (the “Website Terms of Use”) govern your relationship with the Prestige Club Program website available at https://www.prestigeclub.in/  (the “Prestige Club Website”). The Prestige Club Website is operated by TLC DigiTech Pvt. Ltd. and its affiliates, subsidiaries, associates and group companies (“TLC”) on behalf of Radisson Hotel Group and their respective affiliates, subsidiaries, associates and group companies (“RHG”) in relation to the Prestige Club membership program (the “Prestige Club Program”).",
                "Please read the Website Terms of Use carefully before using the Prestige Club Website. Any submission of information by you on the Prestige Club Website is subject to TLC’s privacy statement available at https://www.prestigeclub.in/ (the “Privacy Policy”).",
                "Your access to and use of the Prestige Club Website is conditioned on your acceptance of and compliance with the Website Terms of Use, the terms and conditions of the Prestige Club Program available at https://www.prestigeclub.in/ (the “Terms and Conditions”) and the Privacy Policy.",
                "The Website Terms of Use apply to all visitors, users, members and others who access or use the Prestige Club Website. By accessing or using the Prestige Club Website you agree to be bound by the Website Terms of Use, the Terms and Conditions and the Privacy Policy.  If you disagree with any part of the Website Terms of Use, the Terms and Conditions or the Privacy Policy, please exit from and discontinue use of the Prestige Club Website immediately. TLC reserves the right, in its discretion, to modify or terminate your access to the Prestige Club Website or any portion thereof at any time, without notice.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "2. Intellectual Property",
            data: [
                "The content and information displayed on the Prestige Club Website is the property of Radisson Hotel Group and/or TLC. The downloading, reproduction, or retransmission of such content and/or information, other than for non-commercial individual use, is strictly prohibited.",
                "The Prestige Club App contains proprietary information, content and materials, including but not limited to domain name, icons, logos, trade names, trade secrets and trademarks in relation to the Radisson Hotel Group brand (the “Radisson Materials”) that are owned by Radisson and is protected by applicable intellectual property and other laws. No licence is granted in respect of those intellectual property rights.  The use of the Prestige Club App and/or website by any person does not grant, transfer to or confer upon such person any right, title or interest in the Radisson Hotel Group material.",
                "The Prestige Club App and/or website also contains proprietary information, content and materials, including but not limited to text, graphics, images, photographs, videos, music, sound, designs, copyright, trademarks, patents, software codes, source code and other underlying technology (the “TLC Materials”), that are owned by TLC and protected by applicable intellectual property and other laws. No licence is granted in respect of those intellectual property rights.  The use of the Prestige Club App and/or website by any person does not grant, transfer to or confer upon such person any right, title or interest in the TLC Materials.",
                "Users agree that they will not use the Radisson Materials and TLC Materials (collectively, the “Intellectual Property”) in any way whatsoever except for use of the services available on the Prestige Club App and/or website in compliance with the Terms and Conditions.  Users must not use, copy, modify, publish, reproduce, distribute, re-transmit, transfer, upload, download, sell, licence, reverse engineer, decompile, or disassemble the Intellectual Property, without the prior written consent of Radisson Hotel Group or TLC (as applicable).  Users must also not engage in any conduct that infringes, violates or misappropriates any Intellectual Property rights of Radisson Hotel Group, TLC or any other party, and such infringement, violation or misappropriation shall amount to a breach of the Terms and Conditions.",
                "Radisson and TLC reserve the right to take any administrative and/or legal action in the event of misuse, infringement, violation or misappropriation of the Intellectual Property by any users.  You agree to indemnify, defend and hold Radisson, TLC and our affiliates, subsidiaries, directors, officers, employees, consultants, agents and other representatives, harmless from and against any and all claims, judgment, deficiencies, damages, liabilities, losses, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly out of or from an infringement or misuse of the Intellectual Property.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "3. Links to the other Websites",
            data: [
                "The Prestige Club Website may contain links to third party websites or services that are not owned or controlled by TLC.",
                "TLC has no control over and assumes no responsibility for the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that TLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or services.",
                "Users of the Prestige Club Website are advised to read the terms and conditions and privacy policies of any third party websites or services that they visit.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "4. Limitation of Liability",
            data: [
                "In no event shall TLC or Radisson, nor their/its directors, employees, partners, agents or suppliers be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Prestige Club Website; (ii) any conduct or content of any third party on the Prestige Club Website; (iii) any content obtained from the Prestige Club Website; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not TLC or Radisson has been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "5. Disclaimers",
            data: [
                "The Prestige Club Website is made available to you on an as-is, as-available basis, without representations or warranties of any kind, whether express or implied. TLC and Radisson specifically disclaims all warranties and conditions of any kind, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.",
                "Your use of the Prestige Club Website is voluntary, by your own choice and at your own risk. TLC and Radisson does not warrant or represent that your access to the Prestige Club Website will be uninterrupted or error-free or that any information, data, content or other material accessible through the Prestige Club Website will be free of viruses, bugs, malwares or other harmful components that may interfere with or damage the operation, security or functionality of your device(s).",
                "Without limiting the foregoing, TLC and Radisson does not represent or warrant that the information on the Prestige Club Website is accurate, complete, reliable, useful, timely, or current. Despite commercially reasonable efforts, the Prestige Club Website and other materials may contain typographical or other errors or inaccuracies, including, without limitation, regarding the benefits of the Prestige Club Program, for which TLC and/or Radisson will not be responsible. TLC and/or Radisson reserves the right to void or refuse to process any transaction arising out of such an error, typo, or misprint.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "6. Indemnification",
            data: [
                "You agree to indemnify, defend and hold TLC and Radisson, harmless from and against any and all claims, judgment, deficiencies, damages, liabilities, losses, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly out of or from (a) a breach of the Website Terms of Use; (b) any allegation that any information or other materials submitted to TLC and/or Radisson infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; (c) the misuse or misappropriation of the benefits available through the Prestige Club Website, or any related product or service by any user and/or (d) any other misuse of the Prestige Club Website.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "7. Binding Decisions",
            data: [
                "All decisions of TLC and Radisson, where applicable in relation to the Prestige Club Website are final and binding on all users.",
                "All interpretations of the Website Terms of Use are at TLC and Radisson’s sole discretion and our decisions will be final and binding.  If the English version of the Website Terms of Use conflicts with any other language version, the English version shall prevail.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "8. Changes",
            data: [
                "TLC and Radisson reserve the right, at their sole discretion, to modify or replace the Website Terms of Use at any time. If a revision is material, an attempt will be made to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at their sole discretion.",
                "By continuing to access or use the Prestige Club Website after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please exit from and discontinue use of the Prestige Club Website immediately.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "9. Governing Law",
            data: [
                "The Website Terms of Use shall be governed and construed in accordance with the substantive laws of India.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "10. Dispute Resolution",
            data: [
                "Any disputes or controversy arising out of or relating to the Website Terms of Use shall be settled by arbitration to be held in New Delhi in accordance with the Arbitration and Conciliation Act, 1996 as amended, in the English language, and shall be heard and determined by an arbitral tribunal composed of a sole arbitrator appointed by agreement between the parties to the dispute hereto.  If the parties fail to reach an agreement with respect to the appointment of an arbitrator within 30 calendar days of any party’s notice to the other party of the existence of a dispute, the parties shall appoint one arbitrator each and the arbitrators appointed by the parties shall appoint a third presiding arbitrator. The decision of the arbitrator(s) shall be final, conclusive and binding on the parties.  Notwithstanding the foregoing, however, nothing contained herein shall be deemed to prevent either party from seeking and obtaining injunctive and equitable relief from any court of competent jurisdiction without the posting of any bond or other security.",
                "Subject to the above, each of the parties submits to the exclusive jurisdiction of the courts at New Delhi.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "11. Contact Us",
            data: [
                "All members may also request assistance from the dedicated member contact centre.  The operating hours for the member contact centre are from 9.30 AM to 6.30 PM Monday to Saturday +91 11 46644600. Members may also direct any queries regarding the Prestige Club Website or the Website Terms of Use by telephone at [+91 11 46644600 ] or by e-mail at [contactus@prestigeclub.in ].  [The Prestige Club Website also supports chat and voice options.]",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
    ],
};

export const paymentGateway = {
    pageHeading: "Payment Terms and Conditions",
    isLi: true,
    pageData: [
        {
            heading: "Payment Terms",
            data: [
                "TLC uses third party secure payment gateways for collections. Collections are done on behalf of the Membership Programs managed by TLC. Each program is governed by its respective terms and conditions and refunds are made subject to those terms. Refunds are made in the same mode in which the payment was made.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
        {
            heading: "Payment Gateway Terms",
            data: [
                "Please read these terms and conditions carefully before using the online payment gateway services provided by TLC Digitech Private Limited (“TLC”) as part of its offerings to restaurants, hotels, hotel companies and other similar clients/partners (collectively, the “Clients”).",
                "TLC provides online payment gateway facilities (“Payment Gateway”) through certain third-party payment aggregators / payment gateways (the “Payment Gateway Service Provider(s)”) on various online platforms owned, controlled and/or developed by TLC for the Clients (“TLC Platforms”), to enable the end-users and customers of the Clients (the “Customers”) to make payments for purchase of products and services provided by Clients on the TLC Platforms.",
                "These terms and conditions shall apply to all Clients who are or will be using the Payment Gateway facility on the TLC Platforms.",
                "All payments on the Payment Gateway are subject to the following conditions:",
            ],
            isSubHeading: true,
            isSubLi: true,
            subHeadingData: [
                {
                    subHeading: "Payments",
                    subData: [
                        "In cases where TLC and the Client have inter-alia agreed under the applicable sales orders or partner forms that payments made by the Customers on the Payment Gateway will be credited directly to the bank account of the Client, all such payments made by Customers on the Payment Gateway will be settled in the bank account of the Client only upon deduction of applicable payment gateway charges as agreed between TLC and the Payment Gateway Service Provider and will be processed and credited into the bank account of the Client within two business days.",
                        "In cases where TLC and the Client have inter-alia agreed under the applicable sales order that payments made by the Customers will be split between TLC and the Client in a ratio specified in the relevant sales order, the Payment Gateway will credit the amounts (in accordance with split settlement requests / NOCs for settlement signed by TLC and the Client and issued to the Payment Gateway) less the commission charged by the Payment Gateway. TLC and the Client will arrive at this split ratio by including the fees payable to TLC under the relevant sales order and also the withholding TDS (in accordance with the Income-tax Act, 1961) and TCS (in accordance with the goods and services Tax laws) as per the applicable rates.",
                    ],
                },
                {
                    subHeading:
                        "Liability: TLC shall not be liable or responsible for:",
                    subData: [
                        "Any errors in relation to incorrect details including incorrect personal and bank account details being quoted by the Client relating to the transactions on the TLC Platforms, and any refusal of payment or declining of the transaction by the credit/debit card supplier for any reason. If the credit/ debit card supplier refuses or declines any payment, TLC shall be under no obligation to inform the Client of such refusal or failure of the payment. The Client should itself check with its bank if there are transaction failures.",
                        "In no event will TLC be liable for any damages whatsoever arising out of the use, down-time, inability to use, or the results of use of the Payment Gateway, any websites linked to the Payment Gateway, or the materials or information contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages.",
                    ],
                },
                {
                    subHeading: "Confidentiality:",
                    subData: [
                        "The Client shall maintain the confidentiality of each transaction routed through the Payment Gateway.",
                    ],
                },
                {
                    subHeading: "Cancellation and Refund Policy:",
                    subData: [
                        "Refunds, if applicable, in case of table reservations and food delivery, will only be permitted provided the services have not been availed by the Customers or the delivery of the order is not initiated/ completed (as applicable), and the refund request is made within the permissible cancellation period of the relevant TLC product on the TLC Platform. The refund requests should be expressly made by the Customers on the TLC Platforms in writing and completion of refunds may take up to two weeks.",
                        "The Payment Gateway shall refund the relevant order amount in the same payment mode through which the Customer had made the payment after deducting applicable charges of the Payment Gateway Service Provider and cancellation fees, if any, and shall deduct the amount in the same ratio from the Client as was credited by the Payment Gateway for the relevant order.",
                        "Please note that the terms and conditions set out herein may be updated from time to time and any changes will be effective immediately on being set out herein. You are requested to be aware of the updated terms from time to time.",
                    ],
                },
            ],
        },
        {
            heading: "Cancellation & Refund",
            data: [
                "Members who wish to discontinue their membership are required to submit a written request of cancellation and refund (if applicable) within 15 days of becoming a Member. Upon submission of a written request, termination is irreversible. The membership account of the concerned Member will be closed, and the total amount of the membership fee will be refunded, provided that no benefits under the program have been used by the Member and the refund requested adheres to the given timeline. The refund will be processed upon the receipt of membership card and certificates, if any along with the written request for the cancellation of membership.",
            ],
            isSubHeading: false,
            isSubLi: false,
            subHeadingData: [{ subHeading: "", subData: [] }],
        },
    ],
};
