import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
    decryptDataFunction,
    formatNumberWithCommas,
} from "../../../utils/utils";
import { StepTwoButtonGroup } from "./StepTwoButtonGroup";
import { ConfirmGstDetails } from "./ConfirmGstDetails";

const ConfirmMembershipDetails = React.memo(({ plan }) => {
    const priceData = decryptDataFunction(
        sessionStorage.getItem("d_plan") ?? ""
    );
    const calculateTotalPrice = () => {
        const amountAfterDiscount = plan?.Price - (priceData?.discount ?? 0);
        const gstAmount = 0.18 * amountAfterDiscount;
        return `₹ ${formatNumberWithCommas(
            Math.ceil(gstAmount + amountAfterDiscount)
        )}`;
    };
    return (
        <section className="confirm-membership-details">
            <Row>
                <Col xs={12} md={12}>
                    <h1>Membership Details</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-membership-text">
                        Preferred Hotels
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-membership-sub-text">
                        Radisson Blu Plaza Hotel, Delhi Airport
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-membership-text">
                        Membership Level
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-membership-sub-text">
                        {plan.levelName}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-membership-text">
                        Base Membership Fee
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-membership-sub-text">
                        ₹ {plan.Price}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-membership-text">
                        Promo Discount
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-membership-sub-text">
                        {priceData?.discount
                            ? `₹ ${formatNumberWithCommas(priceData.discount)}`
                            : "0"}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-membership-text">GST</div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-membership-sub-text">18.0%</div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-membership-text">Total Amount</div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-membership-sub-text">
                        {calculateTotalPrice()}
                    </div>
                </Col>
            </Row>
        </section>
    );
});

const ConfirmPersonalDetails = React.memo(({ setStep }) => {
    const userSavedData = decryptDataFunction(
        sessionStorage.getItem("userData")
    );
    const paymentLinkFlag = sessionStorage.getItem("flag");
    const countrySelectData = decryptDataFunction(
        sessionStorage.getItem("c_data")
    );
    return (
        <section className="confirm-personal-details">
            <Row>
                <Col xs={7} md={10}>
                    <h2>Personal Details</h2>
                </Col>
                <Col xs={5} md={2}>
                    {!paymentLinkFlag && (
                        <div
                            className="edit-btn cp"
                            onClick={() => {
                                setStep("1");
                                sessionStorage.setItem("step", "1");
                            }}
                        >
                            Edit
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-personal-text">Full Name</div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-personal-sub-text">
                        {userSavedData?.fName} {userSavedData?.lName}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-personal-text">Mobile Number</div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-personal-sub-text">
                        {paymentLinkFlag
                            ? `+${userSavedData?.mobile}`
                            : `+${countrySelectData.isd}${userSavedData?.mobile}`}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <div className="confirm-personal-text">Email</div>
                </Col>
                <Col xs={6} md={8}>
                    <div className="confirm-personal-sub-text">
                        {userSavedData?.email}
                    </div>
                </Col>
            </Row>
        </section>
    );
});

const StepTwo = ({ plan, setStep }) => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <ConfirmMembershipDetails plan={plan} />
            <ConfirmPersonalDetails setStep={setStep} />
            <ConfirmGstDetails setStep={setStep} />
            <StepTwoButtonGroup setStep={setStep} plan={plan} />
        </>
    );
};

export default React.memo(StepTwo);
