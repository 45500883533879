import React, { useEffect, useState } from "react";
import { decryptDataFunction, encryptDataFunction } from "../../../utils/utils";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { GST_NUMBER_REGEX, NUMBER_REGEX } from "../../../utils/regex";
import gstStateData from "../../../utils/gstCodeWithState.json";
import "./AddGstModal.scss";
import { getCall } from "../../../utils/AxiosInterseptor";
import { VALIDATE_PINCODE } from "../../../utils/axiosUtil";
import { useDispatch } from "react-redux";
import { setPageLoader } from "../../../redux/loader-slice/loaderSlice.js";

const AddGstModal = ({
    show,
    onClose,
    sessionUserSavedGstData,
    setSessionUserSavedGstData,
}) => {
    const dispatch = useDispatch();
    const [userGstData, setUserGstData] = useState({
        id: "",
        gstNum: "",
        cName: "",
        address1: "",
        address2: "",
        state: "",
        pinCode: "",
        country: "India",
    });
    const [userGstDataValid, setUserGstDataValid] = useState({
        gstNum: true,
        cName: true,
        address1: true,
        address2: true,
        pinCode: true,
    });
    const userGstChangeHandler = (e) => {
        let { name, value } = e.target;
        if (name === "pinCode") {
            const isNum = NUMBER_REGEX.test(value);
            if (!isNum || value.length > 6) return;
        }
        if (name === "cName" || name === "address1" || name === "address2") {
            if (value[0]) {
                value = value[0].toUpperCase() + value.slice(1);
            }
        }
        if (name === "gstNum") {
            value = value.toUpperCase().trim();
            if (value.length === 15) {
                const state = gstStateData.find(
                    (data) => data.code === value.slice(0, 2)
                );
                if (state) {
                    setUserGstData((prev) => ({
                        ...prev,
                        state: state.name,
                    }));
                } else {
                    setUserGstData((prev) => ({ ...prev, state: "" }));
                }
            }
            if (value.length > 15) {
                return;
            }
        }
        setUserGstDataValid((prev) => ({ ...prev, [name]: true }));
        setUserGstData((prev) => ({ ...prev, id: "", [name]: value }));
    };
    const validateUserGstInput = () => {
        const gstNumValid = GST_NUMBER_REGEX.test(userGstData.gstNum);
        const cNameValid = userGstData.cName.trim() !== "";
        const add1Valid = userGstData.address1.trim() !== "";
        const add2Valid = userGstData.address2.trim() !== "";
        const pinCodeValid =
            userGstData.pinCode.length === 6 ? userGstDataValid.pinCode : false;
        if (
            !gstNumValid ||
            !cNameValid ||
            !add1Valid ||
            !add2Valid ||
            !pinCodeValid
        ) {
            const data = {
                gstNum: gstNumValid ? userGstData.state !== "" : gstNumValid,
                cName: cNameValid,
                address1: add1Valid,
                address2: add2Valid,
                pinCode: pinCodeValid,
            };
            setUserGstDataValid(data);
            return false;
        }
        return true;
    };
    const saveGstDetailHandler = () => {
        const isUserGstValid = validateUserGstInput();
        if (!isUserGstValid) {
            return;
        }
        const encryptedUserGstData = encryptDataFunction(userGstData);
        sessionStorage.setItem("userGstData", encryptedUserGstData);
        setSessionUserSavedGstData(userGstData);
        onClose();
    };
    useEffect(() => {
        if (sessionUserSavedGstData && show) {
            setUserGstData(sessionUserSavedGstData);
        }
    }, [sessionUserSavedGstData, show]);
    useEffect(() => {
        const validatePinCode = async () => {
            dispatch(setPageLoader(true));
            const response = await getCall(
                VALIDATE_PINCODE(userGstData.pinCode)
            );
            dispatch(setPageLoader(false));
            if (response.msg !== "Success") {
                return;
            }
            if (response.data[0].Status !== "Success") {
                setUserGstDataValid((prev) => ({
                    ...prev,
                    pinCode: false,
                }));
                return;
            }
            if (
                (response.data[0]?.PostOffice[0]?.State ?? "") !==
                userGstData.state
            ) {
                setUserGstDataValid((prev) => ({
                    ...prev,
                    pinCode: false,
                }));
            } else {
                setUserGstDataValid((prev) => ({ ...prev, pinCode: true }));
            }
        };
        if (userGstData.pinCode.length === 6) {
            validatePinCode();
        }
    }, [dispatch, setUserGstDataValid, userGstData.pinCode, userGstData.state]);
    return (
        <Modal
            id="add-gst-modal"
            size="lg"
            show={show}
            backdrop="static"
            centered
            onHide={onClose}
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <Row>
                    <Col className="gst-modal-heading">GST Details</Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Control
                            className={
                                userGstDataValid.gstNum ? "" : "invalid-bottom"
                            }
                            value={userGstData.gstNum}
                            onChange={userGstChangeHandler}
                            name="gstNum"
                            type="text"
                            placeholder="GST Number"
                        />
                    </Col>
                    <Col xs={12} md={6} className="mobile-row">
                        <Form.Control
                            className={
                                userGstDataValid.cName ? "" : "invalid-bottom"
                            }
                            value={userGstData.cName}
                            onChange={userGstChangeHandler}
                            name="cName"
                            type="text"
                            placeholder="Company Name"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Control
                            className={
                                userGstDataValid.address1
                                    ? ""
                                    : "invalid-bottom"
                            }
                            value={userGstData.address1}
                            onChange={userGstChangeHandler}
                            name="address1"
                            type="text"
                            placeholder="Add 1"
                        />
                    </Col>
                    <Col xs={12} md={6} className="mobile-row">
                        <Form.Control
                            className={
                                userGstDataValid.address2
                                    ? ""
                                    : "invalid-bottom"
                            }
                            value={userGstData.address2}
                            onChange={userGstChangeHandler}
                            name="address2"
                            type="text"
                            placeholder="Add 2"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Select
                            className="cd"
                            value={userGstData.state}
                            aria-label="State"
                            name="state"
                            disabled
                        >
                            <option value="" hidden>
                                State
                            </option>
                            {gstStateData.map((data, i) => (
                                <option key={i} value={data.name}>
                                    {data.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Control
                            className={
                                userGstDataValid.pinCode ? "" : "invalid-bottom"
                            }
                            value={userGstData.pinCode}
                            onChange={userGstChangeHandler}
                            name="pinCode"
                            type="text"
                            placeholder="Pin Code"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Select
                            className="cd"
                            aria-label="Country"
                            name="country"
                            value={userGstData.country}
                            disabled
                        >
                            <option value="India">India</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={saveGstDetailHandler}>Submit</button>
            </Modal.Footer>
        </Modal>
    );
};

export const ConfirmGstDetails = React.memo(({ setStep }) => {
    const [showGstModal, setShowGstModal] = useState(false);
    const [sessionUserSavedGstData, setSessionUserSavedGstData] = useState("");
    const paymentLinkFlag = sessionStorage.getItem("flag");
    const toggleModal = () => {
        setShowGstModal(!showGstModal);
    };
    useEffect(() => {
        const gstData = decryptDataFunction(
            sessionStorage.getItem("userGstData")
        );
        if (gstData) {
            setSessionUserSavedGstData(gstData);
        }
    }, []);
    return (
        <>
            <AddGstModal
                show={showGstModal}
                onClose={toggleModal}
                sessionUserSavedGstData={sessionUserSavedGstData}
                setSessionUserSavedGstData={setSessionUserSavedGstData}
            />
            {!sessionUserSavedGstData ? (
                paymentLinkFlag ? (
                    <section className="link-based-gst-section">
                        <button className="add-gst-btn" onClick={toggleModal}>
                            Add GST Details
                        </button>
                    </section>
                ) : (
                    <></>
                )
            ) : (
                <section className="confirm-gst-details">
                    <Row>
                        <Col xs={7} md={10}>
                            <h2>GST Details</h2>
                        </Col>
                        <Col xs={5} md={2}>
                            <div
                                className="edit-btn cp"
                                onClick={() => {
                                    if (paymentLinkFlag) {
                                        toggleModal();
                                    } else {
                                        setStep("1");
                                        sessionStorage.setItem("step", "1");
                                    }
                                }}
                            >
                                Edit
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="confirm-gst-text">GST Number</div>
                        </Col>
                        <Col xs={6} md={8}>
                            <div className="confirm-gst-sub-text">
                                {sessionUserSavedGstData?.gstNum}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="confirm-gst-text">Company Name</div>
                        </Col>
                        <Col xs={6} md={8}>
                            <div className="confirm-gst-sub-text">
                                {sessionUserSavedGstData?.cName}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="confirm-gst-text">Address</div>
                        </Col>
                        <Col xs={6} md={8}>
                            <div className="confirm-gst-sub-text">
                                {sessionUserSavedGstData?.address1}&nbsp;
                                {sessionUserSavedGstData?.address2}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="confirm-gst-text">State</div>
                        </Col>
                        <Col xs={6} md={8}>
                            <div className="confirm-gst-sub-text">
                                {sessionUserSavedGstData?.state}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="confirm-gst-text">Pin Code</div>
                        </Col>
                        <Col xs={6} md={8}>
                            <div className="confirm-gst-sub-text">
                                {sessionUserSavedGstData?.pinCode}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="confirm-gst-text">Country</div>
                        </Col>
                        <Col xs={6} md={8}>
                            <div className="confirm-gst-sub-text">
                                {sessionUserSavedGstData?.country}
                            </div>
                        </Col>
                    </Row>
                </section>
            )}
        </>
    );
});
