import React from "react";
import { Container } from "react-bootstrap";
import "./PaymentMessageSection.scss";
import { useNavigate } from "react-router-dom";
import { decryptDataFunction } from "../../utils/utils";

const PaymentMessageSection = ({ children, headingText, headingIcon }) => {
    const userData = decryptDataFunction(sessionStorage.getItem("userData"));
    const navigate = useNavigate();
    return (
        <section className="payment-message-section">
            <Container>
                <div className="payment-box">
                    <img src={headingIcon} alt="" />
                    <div className="payment-text">
                        <h1>{headingText}</h1>
                        <p>
                            <b>
                                Dear {userData.fName} {userData.lName},
                            </b>
                        </p>
                        {children}
                        <p>
                            Yours sincerely,
                            <br />
                            <b>Team Prestige Club</b>
                        </p>
                        <button
                            type="submit"
                            className="home-btn"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Go to Home Page
                        </button>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default PaymentMessageSection;
