import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer mt-5">
                <Container className="page-footer-container">
                    <Row>
                        <Col md={2} xs={4} className="footer-link-section">
                            <p>
                                <strong>Policy</strong>
                            </p>
                            <Link to="/disclaimer">
                                <p>Disclaimer</p>
                            </Link>
                            <Link
                                to="https://www.tlcgroup.com/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>Privacy Policy</p>
                            </Link>
                        </Col>
                        <Col
                            md={2}
                            xs={5}
                            className="footer-link-section middle-mobile"
                        >
                            <p>
                                <strong>Terms of Use</strong>
                            </p>
                            <Link to="/website-use">
                                <p>Website Use</p>
                            </Link>
                            <Link to="/payment-gateway">
                                <p>Payment Gateway</p>
                            </Link>
                            <Link
                                to="https://www.tlcgroup.com/general-terms-tlc-saas-products"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>TLC SaaS Products</p>
                            </Link>
                            <Link
                                to="https://www.tlcgroup.com/general-terms-and-conditions-of-tlc-products-and-tlc-platforms"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>TLC Platforms</p>
                            </Link>
                        </Col>
                        <Col md={2} xs={3} className="footer-link-section">
                            <p>
                                <strong>Social</strong>
                            </p>
                            <Link
                                to="https://www.facebook.com/Prestige.Cl"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>Facebook</p>
                            </Link>
                            <Link
                                to="https://www.instagram.com/prestigeclub.app/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>Instagram</p>
                            </Link>
                        </Col>
                        <Col md={6} xs={12} className="footer-detail-section">
                            <p>
                                <span>
                                    Prestige Club program is operated by TLC
                                    DigiTech Pvt. Ltd. and its affiliates.
                                </span>
                                <br />
                                <span>
                                    501 Salcon Aurum, Jasola District Centre,
                                    New Delhi – 110025, India.
                                </span>
                                <br />
                                <Link
                                    to="https://www.tlcgroup.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.tlcgroup.com
                                </Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default React.memo(Footer);
