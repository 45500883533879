import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { decryptDataFunction } from "../../../utils/utils";
import { postCall } from "../../../utils/AxiosInterseptor";
import {
    CREATE_MEMBER_ACCOUNT,
    INITIATE_PAYMENT,
} from "../../../utils/axiosUtil";
import CustomModal from "../../custom-modal/CustomModal";
import { useNavigate } from "react-router-dom";
import linkInvalidIcon from "../../../assets/link-invalid.svg";
import { useDispatch } from "react-redux";
import { setPaymentLoader } from "../../../redux/loader-slice/loaderSlice";

export const StepTwoButtonGroup = React.memo(({ setStep, plan }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tc, setTc] = useState(false);
    const [errorModalState, setErrorModalState] = useState({
        modalId: "#generic-msg",
        show: false,
        content: "Something went wrong. Please try again!",
        icon: linkInvalidIcon,
        btnText: "OK",
    });
    const userSavedData = decryptDataFunction(
        sessionStorage.getItem("userData")
    );
    const selectedPlanDetails = decryptDataFunction(
        sessionStorage.getItem("plan")
    );
    const userGstDetails = decryptDataFunction(
        sessionStorage.getItem("userGstData")
    );
    const planDiscountDetails = decryptDataFunction(
        sessionStorage.getItem("d_plan")
    );
    const paymentLinkFlag = sessionStorage.getItem("flag");
    const createMemberAccount = async () => {
        const countrySelectData = decryptDataFunction(
            sessionStorage.getItem("c_data")
        );
        const payload = {
            name: {
                salutation: userSavedData?.salutation ?? "",
                firstName: userSavedData?.fName ?? "",
                lastName: userSavedData?.lName ?? "",
            },
            countryCode: `+${countrySelectData?.isd}`,
            mobile: +`${countrySelectData?.isd}${userSavedData?.mobile}`,
            email: userSavedData?.email ?? "",
            userImage: "",
        };
        const response = await postCall(CREATE_MEMBER_ACCOUNT(), payload);
        if (response.msg !== "Success") {
            return false;
        }
        sessionStorage.setItem("memberId", response.data.memberId);
        sessionStorage.setItem("sfId", response.data.sfid);
        return true;
    };
    const getIpAddress = async () => {
        try {
            const response = await fetch("https://api.ipify.org?format=json");
            const ipAddress = await response.json();
            return ipAddress?.ip ?? "";
        } catch (error) {
            return "";
        }
    };
    const createPaymentBody = async () => {
        dispatch(setPaymentLoader(true));
        const ipAddress = await getIpAddress();
        const amountAfterDiscount =
            plan?.Price - (planDiscountDetails?.discount ?? 0);
        const gstAmount = (0.18 * amountAfterDiscount)?.toString() ?? "";
        const finalAmount =
            Math.ceil(+gstAmount + +amountAfterDiscount)?.toString() ?? "";
        const body = {
            memberSfid: sessionStorage.getItem("sfId") ?? "",
            transactionType:
                sessionStorage.getItem("t_type") ?? "Membership-Buy",
            membershipType: selectedPlanDetails?.customerSetID ?? "",
            membershipTypeOffer: [],
            consumerId: sessionStorage.getItem("memberId") ?? "",
            membershipNumber: sessionStorage.getItem("m_num") ?? "",
            amount: selectedPlanDetails?.Price?.toString(),
            promocode: planDiscountDetails?.promoCode ?? "",
            discount: planDiscountDetails?.discount?.toString() ?? "0",
            gstNumber: userGstDetails?.gstNum ?? "",
            gstsfid: userGstDetails?.id ?? "",
            gstCompanyName: userGstDetails?.cName ?? "",
            address: {
                addressType: "", //TODO
                country: userGstDetails?.country ?? "",
                addressLine1: userGstDetails?.address1 ?? "",
                addressLine2: userGstDetails?.address2 ?? "",
                city: "",
                state: userGstDetails?.state ?? "",
                pinCode: userGstDetails?.pinCode ?? "",
            },
            ipAddress,
            netAmount: (+selectedPlanDetails?.Price)?.toFixed(2)?.toString(),
            finalAmount,
            gstAmount,
            quantity: "1.0",
            currency: "INR",
        };
        const paymentLinkFlag = sessionStorage.getItem("flag");
        if (paymentLinkFlag) {
            body["leadid"] = sessionStorage.getItem("l_id") ?? "";
        }
        return body;
    };
    const handleGatewayResponse = (gatewayResponse) => {
        if (
            typeof gatewayResponse != "undefined" &&
            typeof gatewayResponse.paymentMethod != "undefined" &&
            typeof gatewayResponse.paymentMethod.paymentTransaction !=
                "undefined" &&
            typeof gatewayResponse.paymentMethod.paymentTransaction
                .statusCode != "undefined" &&
            gatewayResponse.paymentMethod.paymentTransaction.statusCode ===
                "0300"
        ) {
            // PASS
            const gatewayResponseBodyToPass = {
                transactionId:
                    gatewayResponse?.stringResponse?.split("|")?.[3] ?? "",
                transactionCode:
                    gatewayResponse?.stringResponse?.split("|")?.[5] ?? "",
                leadid: sessionStorage.getItem("l_id") ?? "",
                paymentGateway: "Paynimo",
            };
            //TODO: Find Encryption and Navigation
            // const encryptedGatewayResponseBodyToPass = encryptDataFunction(
            //     gatewayResponseBodyToPass
            // );
            // sessionStorage.setItem(
            //     "gate_data",
            //     encryptedGatewayResponseBodyToPass
            // );
            sessionStorage.setItem(
                "gate_data",
                JSON.stringify(gatewayResponseBodyToPass)
            );
            setTimeout(() => {
                window.location.href = "/membership-details/success";
            }, 1000);
            return;
        }
        //FAIL
        window.location.href = "/membership-details/fail";
    };
    const openPaymentGateway = (gatewayData) => {
        const callPayment = document.createElement("script");
        callPayment.text = `(function () {
            const gatewayConfigJSON = {
                "tarCall": false,
                "features": {
                    "showPGResponseMsg": true,
                    "enableAbortResponse": true,
                    "enableExpressPay": true,
                    "enableNewWindowFlow": true, //for hybrid applications please disable this by passing false
                },
                "consumerData": {
                    "deviceId": "WEBSH2", //possible values 'WEBSH1' or 'WEBSH2'
                    "token": "${gatewayData.token}",
                    "responseHandler": ${handleGatewayResponse},
                    "paymentMode": "all",
                    "merchantLogoUrl": "${gatewayData.merchantLogoUrl}", 
                    "merchantId": "${gatewayData.merchantId}",
                    "currency": "INR",
                    "consumerId": "${gatewayData?.consumerId}",
                    "consumerMobileNo": "${gatewayData?.consumerMobileNo}",
                    "consumerEmailId": "${gatewayData?.consumerEmailId}",
                    "txnId": "${
                        gatewayData?.txnId
                    }", //Unique merchant transaction ID
                    "items": ${JSON.stringify(gatewayData?.items)},
                    "customStyle": ${JSON.stringify(gatewayData?.customStyle)},
                },
            };
            window.$.pnCheckout(gatewayConfigJSON);
            if (gatewayConfigJSON.features.enableNewWindowFlow) {
                pnCheckoutShared.openNewWindow();
            }
        })();`;
        document.body.appendChild(callPayment);
    };
    const initiatePayment = async () => {
        const paymentBody = await createPaymentBody();
        const response = await postCall(INITIATE_PAYMENT(), paymentBody);
        if (response.msg !== "Success") {
            if (response.errorCode === "TLC-1018") {
                setErrorModalState((prev) => ({
                    ...prev,
                    content:
                        "You have already reached the limit for maximum memberships allowed. Please reach us at our Contact Centre for assistance.",
                    show: true,
                }));
                return false;
            }
            setErrorModalState((prev) => ({ ...prev, show: true }));
            return false;
        }
        sessionStorage.setItem("l_id", response.data.leadid);
        if (response.data.statusMessage) {
            return true;
        }
        openPaymentGateway(response.data.paymentgatewaydata);
    };
    const makePayment = async () => {
        const memberId = sessionStorage.getItem("memberId");
        if (!memberId) {
            const userCreated = await createMemberAccount();
            if (!userCreated) {
                setErrorModalState((prev) => ({ ...prev, show: true }));
                return;
            }
        }
        const paymentInitiated = await initiatePayment();
        if (paymentInitiated) {
            sessionStorage.setItem("z_flag", true);
            navigate("/membership-details/success");
        }
    };
    const closeModal = () => {
        setErrorModalState((prev) => ({
            ...prev,
            content: "Something went wrong. Please try again!",
            show: false,
        }));
    };
    return (
        <>
            <section className="confirm-details-btn-group">
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Form.Check type="checkbox" id="payment-tc">
                                <Form.Check.Input
                                    type="checkbox"
                                    name="payment-tc"
                                    value={tc}
                                    onChange={(e) => {
                                        setTc(e.target.checked);
                                    }}
                                />
                                <Form.Check.Label>
                                    By offering the content above, I give
                                    permission to Prestige Club to make contact
                                    <br /> with me to receive more information
                                    and agree to the Terms & Conditions and the{" "}
                                    <br />
                                    Privacy Statement on the website.
                                </Form.Check.Label>
                            </Form.Check>
                        </div>
                    </Col>
                </Row>
                <div
                    className={`step-2-btn-grp ${
                        paymentLinkFlag ? "payment-link-btn" : ""
                    }`}
                >
                    {!paymentLinkFlag && (
                        <button
                            className="back-btn rounded"
                            onClick={() => {
                                setStep("1");
                                sessionStorage.setItem("step", "1");
                            }}
                        >
                            Back
                        </button>
                    )}
                    <button
                        className="next-btn rounded"
                        onClick={makePayment}
                        disabled={!tc}
                    >
                        Make Payment
                    </button>
                </div>
            </section>
            <CustomModal modalState={errorModalState} onClose={closeModal} />
        </>
    );
});
