import React, { useEffect } from "react";
import bannerImage from "../assets/banner-thankyou.webp";
import bannerImageMobile from "../assets/membership-banner-mobile.webp";
import HeroSection from "../components/heroSection/HeroSection";
import Footer from "../components/footer/Footer";
import BuyMembershipSection from "../components/buyMembershipSection/BuyMembershipSection";
import Header from "../components/header/Header";
import { Helmet } from "react-helmet";

const BuyMemberBenefitPage = () => {
    useEffect(() => {
        // Payment Gateway Script
        const script = document.createElement("script");
        script.src =
            "https://www.paynimo.com/Paynimocheckout/server/lib/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="The Prestige Club membership program offers exclusive dining, stay, wellness  and other benefits to its members. All membership details and terms can be accessed here."
                />
                <title>
                    Prestige Club | Details of the Membership | Participating
                    Radisson Hotels
                </title>
            </Helmet>
            <Header />
            <HeroSection
                src={bannerImage}
                mobileSrc={bannerImageMobile}
                showForm={false}
            />
            <BuyMembershipSection />
            <Footer />
        </>
    );
};

export default BuyMemberBenefitPage;
