import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./AcceptCookieModal.scss";

const AcceptCookieModal = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const cookieConsent = document.cookie
            .split("; ")
            .find((row) => row.startsWith("cookieConsent="));
        if (!cookieConsent) {
            setShow(true);
        }
    }, []);
    return show ? (
        <div className="cookie-consent-modal">
            <div className="cookie-consent">
                <p className="cookie-consent-heading">
                    We use cookies to improve your experience
                </p>
                <p className="cookie-consent-content">
                    We use cookies essential for this site to function well,
                    tailor your experience and measure site performance. By
                    clicking the Accept and Close button, you agree that cookies
                    can be placed in accordance with our&nbsp;
                    <a
                        href="https://www.tlcgroup.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    .
                </p>
            </div>
            <div>
                <Button
                    className="cookie-consent-btn"
                    onClick={() => {
                        document.cookie =
                            "cookieConsent=true; path=/; max-age=31536000";
                        setShow(false);
                    }}
                >
                    Accept & Close
                </Button>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default AcceptCookieModal;
