import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";
import privilegeDining from "../../assets/privilegeDining.webp";
import privilegeStay from "../../assets/privilegeStay.webp";
import privilegeSpa from "../../assets/privilegeSpa.webp";
import "./MembershipPrivilegeSection.scss";
import Slider from "react-slick";

const PrivilegeTile = ({ data: { title, subText, src, alt } }) => {
    return (
        <Card className="border-0 membership-privilege-tile-card">
            <Card.Img variant="top" src={src} alt={src} />
            <Card.Body className="text-center">
                <Card.Title className="membership-privilege-tile-heading">
                    {title}
                </Card.Title>
                <Card.Text className="membership-privilege-tile-subText">
                    {subText}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

const MembershipPrivilegeSection = () => {
    const privilegeData = [
        {
            title: "Dining",
            subText: "Up to 50% off on the food and beverage bill.",
            src: privilegeDining,
            alt: "Prestige Club wine glasses clinking",
        },
        {
            title: "Stay",
            subText: "Exciting offers on indulgent stays.",
            src: privilegeStay,
            alt: "Prestige Club stay image",
        },
        {
            title: "Spa",
            subText: "Up to 50% off on revitalising spa therapies.",
            src: privilegeSpa,
            alt: "Prestige Club spa image",
        },
    ];
    const [sliderPosition, setSliderPosition] = useState(0);
    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        afterChange: (e) => {
            setSliderPosition(e);
        },
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    className: `center ${
                        sliderPosition === 0 ? "first-element-padding" : ""
                    }`,
                    centerMode: true,
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <Container className="homepage-container">
                <section className="membership-privilege-section my-5rem">
                    <div className="text-center px-3">
                        <h1 className="membership-privilege-heading">
                            Indulgence at its Best
                        </h1>
                        <p className="membership-privilege-heading-subText">
                            Enjoy the privileges of the Prestige Club membership
                            with exclusive benefits on dining, accommodation,
                            wellness and more, at participating Radisson hotels
                            in India.
                        </p>
                    </div>
                    <div className="membership-privilege-slider-container">
                        <Slider {...sliderSettings}>
                            {privilegeData.map((data, i) => (
                                <PrivilegeTile key={i} data={data} />
                            ))}
                        </Slider>
                    </div>
                </section>
            </Container>
        </>
    );
};

export default MembershipPrivilegeSection;
