import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import Footer from "../components/footer/Footer";
import OverviewSection from "../components/overviewSection/OverviewSection";
import bannerImage from "../assets/banner-detail-page.webp";
import { useParams } from "react-router-dom";
import {
    defaultOfferCarousalData,
    delhiOfferCarousalData,
    jalandharOfferCarousalData,
} from "../utils/offerAndEventsData";
import { Helmet } from "react-helmet";

const OverviewPage = () => {
    const { offerKey = "" } = useParams();
    const dataArray = offerKey.startsWith("delhi")
        ? delhiOfferCarousalData
        : offerKey.startsWith("jalandhar")
        ? jalandharOfferCarousalData
        : defaultOfferCarousalData;
    const offerData = dataArray?.find((itm) => itm.key === offerKey);
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    Prestige Club| Membership of Participating Radisson Hotels
                </title>
            </Helmet>
            <Header />
            <HeroSection
                imageClassName="banner-img-aspect"
                src={offerData.overViewBanner ?? bannerImage}
                showForm={false}
            />
            <OverviewSection data={offerData} />
            <Footer />
        </>
    );
};

export default OverviewPage;
