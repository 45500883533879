import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PaymentMessageSection from "../components/paymentMessageSection/PaymentMessageSection";
import paymentFailIcon from "../assets/unsuccessicon.svg";
import { Helmet } from "react-helmet";

const PaymentFailPage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="This page is to notify you that the payment towards your Prestige Club membership could not be processed."
                />
                <title>Prestige Club | Payment Unsuccessful Notification</title>
            </Helmet>
            <Header />
            <PaymentMessageSection
                headingText={"Payment Unsuccessful"}
                headingIcon={paymentFailIcon}
            >
                <p>
                    Your payment request could not be processed. We regret the
                    inconvenience caused. One of our associates will contact you
                    within 24 business hours.
                </p>
            </PaymentMessageSection>
            <Footer />
        </>
    );
};

export default PaymentFailPage;
