import React, { useEffect } from "react";
import "./BenefitsTable.scss";

const TickIconSvg = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.567.025c1.696 0 3.268.496 4.593 1.348l-.209.177c-.357.321-.696.642-1.034.98a7.052 7.052 0 0 0-3.35-.86c-3.802 0-6.897 3.095-6.897 6.897 0 3.801 3.095 6.896 6.897 6.896 3.8 0 6.896-3.095 6.914-6.896 0-.738-.125-1.457-.34-2.123l.06-.078a7.42 7.42 0 0 1 .292-.362c.263-.334.543-.666.84-1a8.618 8.618 0 0 1 .775 3.563c0 4.711-3.83 8.541-8.541 8.541-4.712 0-8.542-3.83-8.542-8.541 0-4.712 3.83-8.542 8.542-8.542z"
                fill="#2C4390"
                stroke="#fff"
                strokeWidth=".05"
            />
            <path
                d="M10.984 6.024a39.196 39.196 0 0 0-1.84 2.435 31.3 31.3 0 0 0-.848 1.28L5.988 6.89a.834.834 0 0 0-1.1-.162.813.813 0 0 0-.216 1.137l2.903 4.31c.072.108.18.216.307.289a.94.94 0 0 0 .523.162c.342 0 .721-.18.884-.487.162-.289.973-1.786 1.514-2.597a35.19 35.19 0 0 1 1.66-2.38 31.349 31.349 0 0 1 1.84-2.255c.63-.722 1.316-1.461 2.001-2.092l.018-.018a.818.818 0 0 0 .054-1.137.807.807 0 0 0-1.154-.072 35.467 35.467 0 0 0-2.2 2.146 30.359 30.359 0 0 0-2.038 2.29z"
                fill="#2C4390"
            />
        </svg>
    );
};

const BenefitsTable = (props) => {
    const {
        clone,
        tableHeading,
        tableHead,
        tableData,
        tableId,
        tableScrollId,
    } = props;
    useEffect(() => {
        if (clone) {
            const mainTable = document.querySelector(`#${tableId}`);
            const clonedTable = mainTable.cloneNode(true);
            const tableScroll = document.getElementById(`${tableScrollId}`);
            clonedTable.classList.add("clone");
            tableScroll.appendChild(clonedTable);
        }
    }, [clone, tableId, tableScrollId]);
    return (
        <>
            <p className="text-center mb-5 member-benefit-sub-heading">
                {tableHeading}
            </p>
            <div id={tableScrollId} className="table-scroll">
                <div className="table-wrap">
                    <table id={tableId} className="main-table">
                        <thead>
                            <tr>
                                <th className="fixed-side font-weight-bold">
                                    {tableHead ?? "Card Benefits"}
                                </th>
                                {tableData?.columnData?.map((colName, i) => (
                                    <th key={i}>{colName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.rowData?.map((data, i) => (
                                <tr key={i}>
                                    <td className="fixed-side">
                                        {data.benefit}
                                    </td>
                                    {data.copper !== undefined && (
                                        <td>
                                            {data.copper === "" ? (
                                                <TickIconSvg />
                                            ) : (
                                                data.copper
                                            )}
                                            {data?.copperSub ? (
                                                <span>{data.copperSub}</span>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    )}
                                    {data.silver !== undefined && (
                                        <td>
                                            {data.silver === "" ? (
                                                <TickIconSvg />
                                            ) : (
                                                data.silver
                                            )}
                                            {data?.silverSub ? (
                                                <span>{data.silverSub}</span>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    )}
                                    {data.gold !== undefined && (
                                        <td>
                                            {data.gold === "" ? (
                                                <TickIconSvg />
                                            ) : (
                                                data.gold
                                            )}
                                            {data?.goldSub ? (
                                                <span>{data.goldSub}</span>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    )}
                                    {data.platinum !== undefined && (
                                        <td>
                                            {data.platinum === "" ? (
                                                <TickIconSvg />
                                            ) : (
                                                data.platinum
                                            )}
                                            {data?.platinumSub ? (
                                                <span>{data.platinumSub}</span>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default BenefitsTable;
