import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./OverviewSection.css";

const OverviewSection = (props) => {
    const { overViewTitle, overViewDescription } = props.data;
    return (
        <>
            <Container>
                <Row>
                    <Col md={12} xs={12}>
                        <div className="overview">
                            <h1>{overViewTitle}</h1>
                            {overViewDescription.map((desp, i) => (
                                <p key={i}>{desp}</p>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default React.memo(OverviewSection);
