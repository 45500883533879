import React from "react";
import MembershipPrice from "./membershipPrice/MembershipPrice";
import "./MemberBenefitSection.css";
import BenefitsTable from "./benefitsTable/BenefitsTable";

const MemberBenefitSection = ({ property, propertyTableData, buyFlag }) => {
    return (
        <>
            <h2 className="text-center mt-5rem member-benefit-heading">
                Membership Benefits
            </h2>
            {propertyTableData.map((data, i) => (
                <BenefitsTable key={i} {...data} />
            ))}
            <MembershipPrice buyFlag={buyFlag} property={property} />
        </>
    );
};

export default React.memo(MemberBenefitSection);
