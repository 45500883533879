import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PaymentMessageSection from "../components/paymentMessageSection/PaymentMessageSection";
import paymentSuccessIcon from "../assets/successicon.svg";
import { useNavigate } from "react-router-dom";
import { UPDATE_PAYMENT } from "../utils/axiosUtil";
import { postCall } from "../utils/AxiosInterseptor";
import { Helmet } from "react-helmet";

const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    const gatewayResponseData = JSON.parse(sessionStorage.getItem("gate_data"));
    const zero_flag = sessionStorage.getItem("z_flag");
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        let flag = false;
        const updatePayment = async (payload) => {
            flag = true;
            const response = await postCall(UPDATE_PAYMENT(), payload);
            if (response.msg !== "Success") {
                return;
            }
            sessionStorage.setItem("update", true);
        };
        const updatePaymentFlag = sessionStorage.getItem("update");
        if (gatewayResponseData && !updatePaymentFlag && !flag) {
            updatePayment(gatewayResponseData);
        } else if (!sessionStorage.getItem("z_flag")) {
            // TODO: Should re-direct?
            // navigate("/");
        }
    }, [gatewayResponseData, navigate]);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="This page is to notify you that the payment towards your Prestige Club membership was processed successfully."
                />
                <title>Prestige Club | Payment Successful Notification</title>
            </Helmet>
            <Header />
            <PaymentMessageSection
                headingText={
                    zero_flag ? "Enrolment Successful" : "Payment Successful"
                }
                headingIcon={paymentSuccessIcon}
            >
                <p>
                    {zero_flag
                        ? "You have successfully enrolled as a Prestige Club member by applying your 100% off Promo Code. The details of your membership will be sent to you separately."
                        : `Your payment has been successful. Your transaction number
                        is ${gatewayResponseData?.transactionCode}. The details of your
                        Prestige Club membership will be sent to you separately.`}
                </p>
                <p>
                    In the interim, please feel free to get in touch with us for
                    any information or assistance.
                </p>
            </PaymentMessageSection>
            <Footer />
        </>
    );
};

export default PaymentSuccessPage;
