import React, { useRef, useState } from "react";
import headerImg from "../../assets/brand_logo.png";
import {
    Container,
    Nav,
    Navbar,
    NavDropdown,
    Offcanvas,
} from "react-bootstrap";
import "./Header.scss";

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const headerRef = useRef(null);
    const toggleMobileView = () => {
        setShowMobileMenu(!showMobileMenu);
    };
    const setHeightVariableForCss = () => {
        if (headerRef.current) {
            document.documentElement.style.setProperty(
                "--header-height",
                `${headerRef.current.clientHeight}px`
            );
        }
    };
    return (
        <>
            <Navbar ref={headerRef} id="custom-nav" variant="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            className="d-block cp"
                            src={headerImg}
                            alt="Prestige Club logo"
                            onLoad={setHeightVariableForCss}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        className={showMobileMenu ? "mobile-show" : ""}
                        aria-controls="basic-navbar-nav"
                        onClick={toggleMobileView}
                    />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="d-none d-lg-flex">
                            <NavDropdown title="Membership Terms">
                                <NavDropdown.Item href="/app-terms-and-conditions">
                                    App
                                </NavDropdown.Item>
                                <hr />
                                <NavDropdown.Item href="/cards-and-certificates">
                                    Cards & Certificates
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Offcanvas
                id="custom-nav-mobile"
                show={showMobileMenu}
                onHide={toggleMobileView}
                placement="top"
                className="lg-none"
                style={{
                    top: `${headerRef?.current?.clientHeight - 1 ?? 0}px`,
                }}
                backdropClassName="custom-nav-mobile-backdrop"
            >
                <Offcanvas.Body>
                    <Nav>
                        <Nav.Link href="/app-terms-and-conditions">
                            Membership Terms (App)
                        </Nav.Link>
                        <Nav.Link href="/cards-and-certificates">
                            Membership Terms (Card & Certificates)
                        </Nav.Link>
                        <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default React.memo(Header);
