import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPageLoading: false,
    isPaymentLoading: false,
};

const loaderSlice = createSlice({
    name: "Loader",
    initialState,
    reducers: {
        setPageLoader(state, action) {
            state.isPageLoading = action.payload;
        },
        setPaymentLoader(state, action) {
            state.isPaymentLoading = action.payload;
        },
    },
});

export const { setPageLoader, setPaymentLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
