import delectableDining from "../assets/videos/Dining.mp4";
import lavishEscape from "../assets/videos/stay-with-us.mp4";
import sereneSpa from "../assets/videos/unwind-with-us.mp4";
import delectableDiningBanner from "../assets/dining_Banner.webp";
import lavishEscapeBanner from "../assets/stay_banner.webp";
import sereneSpaBanner from "../assets/spa_banner.webp";
import royalAwadhiBanner from "../assets/Roayl-Awadhi.jpg";

export const defaultOfferCarousalData = [
    {
        key: "home-delectable-dining",
        image: false,
        offerTitle: "Delectable Dining",
        offerDetail: "Enjoy 20% off on dining",
        offerAsset: delectableDining,
        overViewBanner: delectableDiningBanner,
        overViewTitle: "Delectable Dining",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
        ],
    },
    {
        key: "home-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "home-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];

export const delhiOfferCarousalData = [
    // {
    //     key: "delhi-delectable-dining",
    //     image: false,
    //     offerTitle: "Delectable Dining",
    //     offerDetail: "Enjoy 20% off on dining",
    //     offerAsset: delectableDining,
    //     overViewBanner: delectableDiningBanner,
    //     overViewTitle: "Delectable Dining",
    //     overViewDescription: [
    //         "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
    //     ],
    // },
    {
        key: "delhi-royal-awadhi",
        image: true,
        offerTitle: "Aadaab Lucknow Food Festival",
        offerDetail: "",
        offerAsset: royalAwadhiBanner,
        overViewBanner: royalAwadhiBanner,
        overViewTitle: "Aadaab Lucknow Food Festival",
        overViewDescription: [
            "Experience Nawabi opulence as we bring forgotten flavours of a glorious heritage alive on your palate. Indulge in centuries old royal Awadhi delicacies like Kakori Kabab, Paneer Firdausi, Hazratganj ka Tandoori Chooza, Kacche Gosht ki Biryani and much more at Aadaab Lucknow.",
            "Date: 30th August - 8th September, 2024",
            "Outlet: The Great Kabab Factory",
            "For reservations, please call: +91 7093875732",
        ],
    },
    {
        key: "delhi-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "delhi-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];

export const jalandharOfferCarousalData = [
    {
        key: "jalandhar-delectable-dining",
        image: false,
        offerTitle: "Delectable Dining",
        offerDetail: "Enjoy 20% off on dining",
        offerAsset: delectableDining,
        overViewBanner: delectableDiningBanner,
        overViewTitle: "Delectable Dining",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
        ],
    },
    {
        key: "jalandhar-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "jalandhar-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];
