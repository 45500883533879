import React, { useEffect, useRef, useState } from "react";
import KnowMoreForm from "./knowMoreForm/KnowMoreForm";
import HeaderBanner from "../../assets/banner-reservation.webp";
import HeaderBannerMobile from "../../assets/banner-home-page.webp";
import "./HeroSection.scss";

const HeroSection = (props) => {
    const { src, mobileSrc, imageClassName, alt, showForm } = props;
    const knowMoreInnerBlockRef = useRef(null);
    const [knowMoreInnerBlockStyle, setKnowMoreInnerBlockStyle] = useState({});
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                const marginBottom =
                    knowMoreInnerBlockRef.current?.getBoundingClientRect()
                        ?.height;
                setKnowMoreInnerBlockStyle({
                    marginBottom: `${Math.floor(marginBottom)}px`,
                });
            } else {
                setKnowMoreInnerBlockStyle({}); // Reset style if condition doesn't meet
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        if (window.innerWidth <= 600) {
            const marginBottom =
                knowMoreInnerBlockRef.current?.getBoundingClientRect()?.height;
            setKnowMoreInnerBlockStyle({
                marginBottom: `${Math.floor(marginBottom)}px`,
            });
        } else {
            setKnowMoreInnerBlockStyle({}); // Reset style if condition doesn't meet
        }
    }, [knowMoreInnerBlockRef, src]);
    return (
        <section
            id="hero-section"
            className={`${showForm ? "knowMore" : ""}`}
            style={knowMoreInnerBlockStyle}
        >
            <picture>
                <source
                    media="(max-width:600px)"
                    srcSet={mobileSrc || src || HeaderBannerMobile}
                />
                <img
                    alt={alt ? alt : "Prestige Club membership main banner"}
                    className={`${
                        imageClassName ?? ""
                    } img-fluid knowMore-img ${
                        showForm ? "membership-banner-image" : ""
                    }`}
                    src={src || HeaderBanner}
                />
            </picture>
            {showForm && (
                <KnowMoreForm knowMoreInnerBlockRef={knowMoreInnerBlockRef} />
            )}
        </section>
    );
};

export default React.memo(HeroSection);
