import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./OfferAndEventSection.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const CarousalItem = (props) => {
    const { offerTitle, offerDetail, offerAsset, key, image, imageAltText } =
        props.data;
    const navigate = useNavigate();
    return (
        <div className="item">
            <div className="position-relative">
                {image ? (
                    <img
                        className="owl-carousel-asset"
                        src={offerAsset}
                        alt={imageAltText}
                    />
                ) : (
                    <video
                        loop
                        muted
                        src={offerAsset}
                        autoPlay
                        className="owl-carousel-asset"
                    />
                )}
                <div className={`position-absolute message-box text-center`}>
                    <p className="message-box-heading">{offerTitle}</p>
                    <p className="message-box-description">{offerDetail}</p>
                    <Button
                        className="btn-text px-3"
                        variant="primary"
                        onClick={() => {
                            navigate(`/overview/${key}`);
                        }}
                    >
                        Know More
                    </Button>
                </div>
            </div>
        </div>
    );
};

const OfferAndEventSection = ({ carousalData }) => {
    return (
        <>
            <h2 className="text-center my-5rem offer-heading">
                Offers and Events
            </h2>
            <OwlCarousel
                className="owl-theme"
                loop={true}
                items={1}
                center={true}
                margin={10}
                responsive={{
                    0: { stagePadding: 0 }, // Set stagePadding to 0 for screens less than 480px wide
                    1920: { stagePadding: 480 },
                    1280: { stagePadding: 130 },
                    800: { stagePadding: 70 },
                    480: { stagePadding: 200 }, // Set stagePadding back to 200 for screens wider than 480px
                }}
            >
                {carousalData.map((offer, i) => (
                    <CarousalItem key={i} data={offer} />
                ))}
            </OwlCarousel>
        </>
    );
};

export default React.memo(OfferAndEventSection);
