import React from "react";
import call from "../../../assets/call-icon.png";
import chat from "../../../assets/chat-icon.png";
import whatsapp from "../../../assets/whatsapp-icon.png";
import addressBook from "../../../assets/DqmF.vcf";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactUsLinks = () => {
    return (
        <div className="contactus">
            <h1>Contact US</h1>
            <p>We welcome your questions & feedback</p>
            <div className="social-icon">
                <Link to="tel:+91 11 46644600">
                    <img
                        className="img-fluid contact-icon"
                        src={call}
                        alt="Prestige Club Call Now icon"
                    />
                </Link>
                <Link to="mailto:contactus@prestigeclub.in">
                    <img
                        className="img-fluid contact-icon"
                        src={chat}
                        alt="Prestige Club Chat Now icon"
                    />
                </Link>
                <Link
                    to="https://wa.me/+919821298085"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="img-fluid contact-icon"
                        src={whatsapp}
                        alt="Prestige Club WhatsApp Now icon"
                    />
                </Link>
            </div>
            <div className="addressbook">
                <a href={addressBook}>
                    <Button>Add To Address Book</Button>
                </a>
            </div>
        </div>
    );
};

export default React.memo(ContactUsLinks);
