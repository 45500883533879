export const FETCH_MEMBERSHIP_PRICE = () => `/program/TLC_RAD_PC?status=Live`;
export const VALIDATE_SIGNUP_PROMOCODE = (promoCode = "") =>
    `/validateSignupPromocode?promocode=${promoCode}&tx=signup&source=Web`;
export const VALIDATE_MEMBERSHIP_PROMOCODE = (
    promoCode = "",
    membershipTypeId = ""
) =>
    `/validatePromocode?tx=Membership-Buy&promoCode=${promoCode}&membershipTypeId=${membershipTypeId}`;
export const SEND_OTP = () => "/sendotp";
export const RESEND_OTP = () => "/resendotp";
export const VALIDATE_OTP = () => "/validateotp";
export const VALIDATE_PINCODE = (pin = "") =>
    `https://api.postalpincode.in/pincode/${pin}`;
export const VERIFY_MEMBER_DETAILS = (phone, email) =>
    `/verifymember?mobile=${phone}&email=${email}`;
export const GET_USER_DETAILS_FROM_LEAD = (leadId) => `/lead?leadid=${leadId}`;
export const CREATE_MEMBER_ACCOUNT = () => `/account`;
export const FETCH_MEMBER_ACCOUNT_BY_MOBILE = (phone) =>
    `/members?mobile=${phone}`;
export const INITIATE_PAYMENT = () => `/payment`;
export const UPDATE_PAYMENT = () => `/updatepayment `;
export const VALIDATE_CERTIFICATE_BY_OTP = (transactionId) =>
    `/validatecertificatebyotp?transcationId=${transactionId}`;
