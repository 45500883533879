import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import Footer from "../components/footer/Footer";
import ContactUsSection from "../components/contactUsSection/ContactUsSection";
import bannerImage from "../assets/banner-contact.webp";
import bannerImageMobile from "../assets/contact-banner-mobile.webp";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Please feel free to connect with the Prestige Club Contact Centre via call, email or whatsapp. We would be happy to assist you."
                />
                <title>Prestige Club | Get in Touch with Us</title>
            </Helmet>
            <Header />
            <HeroSection
                src={bannerImage}
                mobileSrc={bannerImageMobile}
                showForm={false}
            />
            <ContactUsSection />
            <Footer />
        </>
    );
};

export default ContactUsPage;
