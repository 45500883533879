import React, { useEffect, useState } from "react";
import {
    GSTDetails,
    UserPersonalDetails,
} from "./buyMembershipForm/BuyMembershipForm";
import { MembershipDetails } from "./buyMembershipDetails/BuyMembershipDetails";
import { EMAIL_REGEX, GST_NUMBER_REGEX } from "../../../utils/regex";
import { getCall, postCall } from "../../../utils/AxiosInterseptor";
import {
    VALIDATE_MEMBERSHIP_PROMOCODE,
    VALIDATE_OTP,
} from "../../../utils/axiosUtil";
import { decryptDataFunction, encryptDataFunction } from "../../../utils/utils";
import CustomModal from "../../custom-modal/CustomModal";
import errorIcon from "../../../assets/link-invalid.svg";

const StepOne = ({ plan, setStep }) => {
    const [promoCode, setPromoCode] = useState({
        value: "",
        valid: true,
        applied: false,
    });
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [userData, setUserData] = useState({
        salutation: "",
        fName: "",
        lName: "",
        mobile: "",
        email: "",
    });
    const [userDataValid, setUserDataValid] = useState({
        salutation: true,
        fName: true,
        lName: true,
        mobile: true,
        email: true,
    });
    const [otp, setOtp] = useState("");
    const [otpState, setOtpState] = useState({
        verified: false,
        inValid: false,
        trId: "",
    });
    const [countryData, setCountryData] = useState({
        isd: "91",
        iso2: "in",
        minLength: 10,
        maxLength: 10,
    });
    const [showGst, setShowGst] = useState(false);
    const [userGstData, setUserGstData] = useState({
        id: "",
        gstNum: "",
        cName: "",
        address1: "",
        address2: "",
        state: "",
        pinCode: "",
        country: "India",
    });
    const [userGstDataValid, setUserGstDataValid] = useState({
        gstNum: true,
        cName: true,
        address1: true,
        address2: true,
        pinCode: true,
    });
    const [otpSentErrorModal, setOtpSentErrorModal] = useState({
        modalId: "#send-otp-error",
        show: false,
        content:
            "We have received multiple OTP request from this mobile number. To maintain security of your account, please try after 15 minutes.",
        btnText: "OK",
        icon: errorIcon,
    });
    const closeOtpErrorModal = () => {
        setOtpSentErrorModal((prev) => ({ ...prev, show: false }));
    };
    const validateUserDataInput = () => {
        const salutationValid = userData.salutation !== "";
        const firstNameValid = userData.fName !== "";
        const lastNameValid = userData.lName !== "";
        const mobileValid =
            userData.mobile.length >= countryData.minLength &&
            userData.mobile.length <= countryData.maxLength;
        const emailValid = EMAIL_REGEX.test(userData.email);
        if (
            !salutationValid ||
            !firstNameValid ||
            !lastNameValid ||
            !mobileValid ||
            !emailValid ||
            !otpState.verified
        ) {
            const data = {
                salutation: salutationValid,
                fName: firstNameValid,
                lName: lastNameValid,
                mobile: mobileValid,
                email: emailValid,
            };
            setUserDataValid(data);
            setOtpState((prev) => ({ ...prev, inValid: !otpState.verified }));
            return false;
        }
        return true;
    };
    const validateUserGstInput = () => {
        if (showGst) {
            const gstNumValid = GST_NUMBER_REGEX.test(userGstData.gstNum);
            const cNameValid = userGstData.cName.trim() !== "";
            const add1Valid = userGstData.address1.trim() !== "";
            const add2Valid = userGstData.address2.trim() !== "";
            const pinCodeValid =
                userGstData.pinCode.length === 6
                    ? userGstDataValid.pinCode
                    : false;
            if (
                !gstNumValid ||
                !cNameValid ||
                !add1Valid ||
                !add2Valid ||
                !pinCodeValid
            ) {
                const data = {
                    gstNum: gstNumValid
                        ? userGstData.state !== ""
                        : gstNumValid,
                    cName: cNameValid,
                    address1: add1Valid,
                    address2: add2Valid,
                    pinCode: pinCodeValid,
                };
                setUserGstDataValid(data);
                return false;
            }
        }
        return true;
    };
    const validatePromoCode = async () => {
        if (promoCode.value.length === 0 || promoCode.applied) {
            return true;
        }
        const response = await getCall(
            VALIDATE_MEMBERSHIP_PROMOCODE(promoCode.value)
        );
        if (response.msg !== "Success") {
            setDiscountedPrice(0);
            setPromoCode((prev) => ({ ...prev, valid: false, applied: true }));
            return false;
        }
        setPromoCode((prev) => ({ ...prev, valid: true, applied: true }));
        const discount = (+response.data.discountRate / 100) * plan.Price;
        setDiscountedPrice(discount ?? 0);
        const paylaod = {
            promoCode: promoCode.value,
            discount,
        };
        const encryptedPriceData = encryptDataFunction(paylaod);
        sessionStorage.setItem("d_plan", encryptedPriceData);
        return true;
    };
    const resetStepOne = () => {
        setPromoCode({
            value: "",
            valid: true,
            applied: false,
        });
        setUserData({
            salutation: "",
            fName: "",
            lName: "",
            mobile: "",
            email: "",
        });
        setUserDataValid({
            salutation: true,
            fName: true,
            lName: true,
            mobile: true,
            email: true,
        });
        setOtp("");
        setOtpState({
            verified: false,
            inValid: false,
            trId: "",
        });
        setCountryData({
            isd: "91",
            iso2: "in",
            minLength: 10,
            maxLength: 10,
        });
        setShowGst(false);
        setUserGstData({
            id: "",
            gstNum: "",
            cName: "",
            address1: "",
            address2: "",
            state: "",
            pinCode: "",
            country: "India",
        });
        setUserGstDataValid({
            gstNum: true,
            cName: true,
            address1: true,
            address2: true,
            pinCode: true,
        });
        sessionStorage.removeItem("step");
        sessionStorage.removeItem("memberId");
        sessionStorage.removeItem("sfId");
        sessionStorage.removeItem("c_data");
        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("d_plan");
        sessionStorage.removeItem("otp");
        sessionStorage.removeItem("userGstData");
        sessionStorage.removeItem("l_id");
        sessionStorage.removeItem("lnk_flg");
    };
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const promoCodeData = decryptDataFunction(
            sessionStorage.getItem("d_plan")
        );
        if (!promoCodeData) {
            return;
        }
        setPromoCode({
            value: promoCodeData.promoCode,
            valid: true,
            applied: true,
        });
        setDiscountedPrice(promoCodeData.discount);
    }, []);
    useEffect(() => {
        let userData = decryptDataFunction(sessionStorage.getItem("userData"));
        let userGstData = decryptDataFunction(
            sessionStorage.getItem("userGstData")
        );
        if (userData) {
            setUserData(userData);
            const otp = sessionStorage.getItem("otp") ?? "";
            if (otp) {
                setOtp(otp);
                setOtpState({
                    verified: true,
                    inValid: false,
                    trId: "",
                });
            }
            const countrySelectData = decryptDataFunction(
                sessionStorage.getItem("c_data")
            );
            setCountryData(countrySelectData);
        }
        if (userGstData !== "") {
            setUserGstData(userGstData);
            setShowGst(true);
        }
    }, []);
    useEffect(() => {
        const ValidateOtp = async () => {
            const payload = {
                otp,
                otpFor: "verify mobile",
                transactionId: otpState?.trId ?? "",
            };
            const response = await postCall(VALIDATE_OTP(), payload);
            if (response.msg !== "Success") {
                setOtpState((prev) => ({ ...prev, inValid: true }));
                return;
            }
            setOtpState({
                verified: true,
                inValid: false,
                trId: "",
            });
        };
        if (otp.length === 4 && !otpState.verified) {
            ValidateOtp();
        }
    }, [otp, otpState.trId, otpState.verified]);
    return (
        <>
            <CustomModal
                modalState={otpSentErrorModal}
                onClose={closeOtpErrorModal}
            />
            <MembershipDetails
                plan={plan}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                discountedPrice={discountedPrice}
                setDiscountedPrice={setDiscountedPrice}
                validatePromoCode={validatePromoCode}
            />
            <hr />
            <UserPersonalDetails
                countryData={countryData}
                setCountryData={setCountryData}
                otp={otp}
                setOtp={setOtp}
                otpState={otpState}
                setOtpState={setOtpState}
                userData={userData}
                setUserData={setUserData}
                setShowGst={setShowGst}
                setUserGstData={setUserGstData}
                userDataValid={userDataValid}
                setUserDataValid={setUserDataValid}
                setOtpSentErrorModal={setOtpSentErrorModal}
            />
            <GSTDetails
                showGst={showGst}
                setShowGst={setShowGst}
                setStep={setStep}
                otp={otp}
                countryData={countryData}
                userData={userData}
                validateUserDataInput={validateUserDataInput}
                userGstData={userGstData}
                setUserGstData={setUserGstData}
                userGstDataValid={userGstDataValid}
                setUserGstDataValid={setUserGstDataValid}
                validateUserGstInput={validateUserGstInput}
                promoCode={promoCode}
                validatePromoCode={validatePromoCode}
                resetStepOne={resetStepOne}
            />
        </>
    );
};

export default React.memo(StepOne);
