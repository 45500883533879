import React from "react";
import "./TermSection.scss";
import { Container } from "react-bootstrap";

const renderSubList = (subDataList, isSubUnordered) => {
    return subDataList.map(
        (
            {
                subHeading,
                subData,
                isSubHeading = false,
                isSubLi = false,
                subHeadingData = [],
            },
            i
        ) => {
            if (isSubHeading) {
                return (
                    <>
                        <div
                            className="term-point-sub-data-child"
                            key={`${i}-${Math.random()}-subHeading${new Date().getTime()}`}
                        >
                            <h6>{subHeading}</h6>
                            <ul className="sub-child-ul">
                                {renderSubList(subHeadingData, isSubLi)}
                            </ul>
                        </div>
                    </>
                );
            }
            return (
                <div
                    className="term-point-sub-data"
                    key={`${i}-${Math.random()}-subHeading${new Date().getTime()}`}
                >
                    <h6>{subHeading}</h6>
                    {renderList(subData, isSubUnordered)}
                </div>
            );
        }
    );
};

const renderList = (
    listData = [],
    isUnordered = false,
    isSubList = false,
    isSubUnordered = false,
    subListData = []
) => {
    return isUnordered ? (
        <ul>
            {listData.map((itm, i) => (
                <li
                    key={`${i}-${Math.random()}-subheadli-${new Date().getTime()}`}
                >
                    {itm}
                </li>
            ))}
            {isSubList ? renderSubList(subListData, isSubUnordered) : null}
        </ul>
    ) : (
        <ol type="a">
            {listData.map((itm, i) => (
                <li
                    key={`${i}-${Math.random()}-subheadli-${new Date().getTime()}`}
                >
                    {itm}
                </li>
            ))}
            {isSubList ? renderSubList(subListData, isSubUnordered) : null}
        </ol>
    );
};

const TermPoint = (props) => {
    const {
        isLi,
        pointData: {
            heading = "",
            data = [],
            isSubHeading,
            isSubLi,
            subHeadingData = [],
        },
    } = props;
    return (
        <div className="term-point">
            <h5>{heading}</h5>
            {renderList(data, isLi, isSubHeading, isSubLi, subHeadingData)}
        </div>
    );
};

const TermSection = (props) => {
    const {
        data: { pageHeading, isLi, pageData = [] },
    } = props;
    return (
        <section>
            <Container className="terms-section-container homepage-container ">
                <h1>{pageHeading}</h1>
                {pageData.map((point, i) => (
                    <TermPoint key={i} isLi={isLi} pointData={point} />
                ))}
            </Container>
        </section>
    );
};

export default TermSection;
