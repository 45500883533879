import React from "react";
import ContactUsLinks from "./contactUsLinks/ContactUsLinks";
import ContactUsForm from "./contactUsForm/ContactUsForm";
import Container from "react-bootstrap/Container";
import "./ContactUsSection.css";

const ContactUsSection = () => {
    return (
        <Container>
            <ContactUsLinks />
            <ContactUsForm />
        </Container>
    );
};

export default React.memo(ContactUsSection);
