import { Modal } from "react-bootstrap";
import React from "react";
import "./CustomModal.scss";

const CustomModal = ({
    modalState: { modalId, show, content, icon, btnText },
    onClose,
}) => {
    return (
        <Modal
            className="custom-common-modal"
            id={modalId}
            show={show}
            backdrop="static"
            centered
        >
            <Modal.Body>
                {icon && <img alt="" src={icon} />}
                <p className="custom-modal-content">{content}</p>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose}>{btnText}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
